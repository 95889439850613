import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faEvents: IconDefinition = {
  prefix: 'fab', // Font AwesomeCustom
  iconName: 'cabinet-filing',
  icon: [
    512,
    512,
    [],
    'e001',
    'M 256 24 C 379.375 24 480 124.625 480 248 C 480 372.25 379.375 472 256 472 C 209.625 472 167.625 458.875 131.75 435.25 C 122.125 429.125 119.5 416 125.625 406.375 C 132.625 396.75 145.75 394.125 155.375 400.25 C 184.25 419.5 218.375 430 256 430 C 355.75 430 438 348.625 438 248 C 438 148.25 355.75 66 256 66 C 191.25 66 134.375 100.125 102 150 L 151 150 C 162.375 150 172 159.625 172 171 C 172 183.25 162.375 192 151 192 L 53 192 C 40.75 192 32 183.25 32 171 L 32 73 C 32 61.625 40.75 52 53 52 C 64.375 52 74 61.625 74 73 L 74 117.625 C 114.25 61.625 180.75 24 256 24 Z M 256 136 C 267.375 136 277 145.625 277 157 L 277 240.125 L 333 296.125 C 341.75 304.875 341.75 318 333 325.875 C 325.125 334.625 312 334.625 304.125 325.875 L 241.125 262.875 C 236.75 259.375 235 254.125 235 248 L 235 157 C 235 145.625 243.75 136 256 136 Z M 256 136',
  ],
};
