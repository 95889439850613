import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Fab, Fade, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Sx } from 'types/Sx';
import { useMenuOptions } from '../Sidebar/useMenuOptions';
import { MobileMenuItem } from './MobileMenuItem';

const menuButtonStyle: Sx = {
  position: 'fixed',
  bottom: 20,
  left: 30,
};
const menuGroupNameStyle: Sx = {
  color: 'primary.main',
  fontSize: 14,
  pl: 2,
  py: 2,
};
const menuListStyle: Sx = {
  width: '100%',
  maxHeight: '100%',
  backgroundColor: 'background.paper',
  color: 'text.primary',
  px: 1,
  borderRadius: 2,
  pb: 2,
};
const menuContainerStyle: Sx = {
  position: 'fixed',
  width: '100%',
  height: '80%',
  bottom: 80,
  left: 7,
  pr: 2,
  zIndex: 999,
};

export const MobileMenu = () => {
  const [isOpened, setOpened] = useState(false);

  const menuOptions = useMenuOptions();

  return (
    <>
      {isOpened && (
        <Box sx={menuContainerStyle}>
          <Fade in={isOpened}>
            <Box sx={menuListStyle}>
              {menuOptions.map((option) => (
                <React.Fragment key={option.groupName}>
                  <Typography variant='h5' sx={menuGroupNameStyle}>
                    {option.groupName}
                  </Typography>
                  <Box display='flex' flexWrap='wrap' rowGap={1}>
                    {option.menuOptions.map((opt) => (
                      <MobileMenuItem
                        key={opt.title}
                        href={opt.href}
                        icon={opt.icon}
                        title={opt.title}
                        disabled={opt.disabled}
                        closeMenu={() => setOpened(false)}
                      />
                    ))}
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Fade>
        </Box>
      )}

      <Fab
        sx={menuButtonStyle}
        color='primary'
        aria-label='add'
        size='large'
        onClick={() => setOpened(!isOpened)}
      >
        {isOpened ? <CloseIcon /> : <MenuIcon />}
      </Fab>
    </>
  );
};
