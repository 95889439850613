import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Sx } from 'types/Sx';
import { DocumentType } from 'api/generated/graphql';

type TermsAndPolicyBottomNavigationProps = {
  documentType: DocumentType | null;
  onTabChange: (val: DocumentType) => void;
};

const selectedTabStyles: Sx = {
  '.Mui-selected': {
    border: 'none',
    '& .MuiTypography-body2': {
      fontWeight: 'bold',
    },
  },
};

export const TermsAndPolicyBottomNavigation = ({
  documentType,
  onTabChange,
}: TermsAndPolicyBottomNavigationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: 'white',
        bottom: 0,
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        left: 0,
      }}
    >
      <Tabs
        value={documentType ?? false} // need to set value prop to false if no Tab should be selected to avoid MUI warnings
        onChange={(_, val) => onTabChange(val)}
        sx={{
          textDecoration: 'underline',
          color: theme.palette.primary.main,
          ...selectedTabStyles,
        }}
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
      >
        <Tab
          label={
            <Typography variant='body2' color='primary' sx={{ textTransform: 'capitalize' }}>
              {t('PRIVACY_POLICY')}
            </Typography>
          }
          value={DocumentType.PrivacyPolicy}
        />
        <Tab
          label={
            <Typography variant='body2' color='primary' sx={{ textTransform: 'capitalize' }}>
              {t('TERMS_AND_CONDITIONS')}
            </Typography>
          }
          value={DocumentType.TermsAndConditions}
        />
      </Tabs>
    </Box>
  );
};
