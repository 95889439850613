import { AssetModelTypeIdentifierFragment } from 'api/generated/graphql';
import { BatteryType } from 'types/assetTypes';
import { AssetSubType } from './types';

// Returns the asset type based on the asset model.
// An asset with charger aspect AND battery pack aspect, will be defined as a charger,
// That is why it is important to first check for charger aspect

export const getAssetSubtype = (
  assetModel?: AssetModelTypeIdentifierFragment | null
): AssetSubType => {
  if (!assetModel) return undefined;

  if (assetModel.isCharger) return assetModel.modelAspects?.charger?.chargerType;
  if (assetModel.isBatteryHub && assetModel.isBatteryPack) return BatteryType.System; // System is a combination of Hub and Pack
  if (assetModel.isBatteryPack) return BatteryType.Pack;
  if (assetModel.isBatteryHub) return BatteryType.Hub;
  return undefined;
};
