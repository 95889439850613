import { Box } from '@mui/material';

type PageProps = {
  sidebarWidth: number | null;
  navbarHeight: number;
  children?: React.ReactNode;
};

export const Page = ({ children, sidebarWidth, navbarHeight }: PageProps) => (
  <Box
    width='100%'
    sx={{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: `calc(100vw - ${sidebarWidth}px)`,
      height: `calc(100vh - ${navbarHeight}px)`,
    }}
  >
    {children}
  </Box>
);
