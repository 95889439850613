import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faChargerBase: IconDefinition = {
  prefix: 'fab', // Font Awesome Custom
  iconName: 'cabinet-filing',
  icon: [
    512,
    512,
    [],
    'e001',
    'M 32.003906 256 C 32.003906 132.625 131.753906 32 256.003906 32 C 379.378906 32 480.003906 132.625 480.003906 256 C 480.003906 327.628906 446.5625 391.113281 394.601562 432.042969 C 386.34375 438.542969 374.558594 436.824219 367.726562 428.839844 C 359.449219 419.167969 361.796875 404.445312 371.613281 396.34375 C 412.046875 362.980469 438.003906 312.527344 438.003906 256 C 438.003906 156.25 355.753906 74 256.003906 74 C 155.378906 74 74.003906 156.25 74.003906 256 C 74.003906 340.519531 131.414062 411.457031 209.417969 432 C 232.25 438.011719 235.082031 428.839844 235.082031 416 L 235.082031 341.375 C 206.207031 332.625 186.082031 305.5 186.082031 274 L 186.082031 239 C 186.082031 227.625 194.832031 218 207.082031 218 L 214.082031 218 L 214.082031 176 C 214.082031 169 220.207031 162 228.082031 162 C 235.082031 162 242.082031 169 242.082031 176 L 242.082031 218 L 270.082031 218 L 270.082031 176 C 270.082031 169 276.207031 162 284.082031 162 C 291.082031 162 298.082031 169 298.082031 176 L 298.082031 218 L 305.082031 218 C 316.457031 218 326.082031 227.625 326.082031 239 L 326.082031 274 C 326.082031 305.5 305.082031 332.625 277.082031 341.375 L 277.082031 432 C 277.082031 464 256.5 489.203125 193.980469 471.398438 C 100.164062 444.675781 32.003906 358.707031 32.003906 256 Z M 284.082031 274 L 284.082031 260 L 228.082031 260 L 228.082031 274 C 228.082031 289.75 240.335938 302 256.082031 302 C 270.960938 302 284.082031 289.75 284.082031 274 Z M 284.082031 274 ',
  ],
};
