import { OrganizationIdentifierFragment } from 'api/generated/graphql';
import { RootRoutes } from 'enums/rootRoutes';
import { NavLink } from 'react-router-dom';
import { AuthorizedLink, AuthorizedLinkProps } from './AuthorizedLink';

type OrganizationLinkProps = Omit<AuthorizedLinkProps, 'to'> & {
  organization: OrganizationIdentifierFragment | undefined | null;
};

export const OrganizationLink = ({ organization, ...rest }: OrganizationLinkProps) => {
  if (!organization) {
    return <>-</>;
  }

  return (
    <AuthorizedLink component={NavLink} {...rest} to={getOrganizationUl(organization.id)}>
      {organization.name}
    </AuthorizedLink>
  );
};

export const getOrganizationUl = (organizationId: string) =>
  `${RootRoutes.ORGANIZATIONS}/${organizationId}`;
