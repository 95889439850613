import { Construction } from '@mui/icons-material';
import { Box, IconButton, Paper } from '@mui/material';
import { useState } from 'react';
import { useScreenSize } from 'utils/hooks/useScreenSize';
import useWindowSizePx from './hooks/useWindowSizePx';

export const DebugInfo = () => {
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  return (
    <Box>
      <IconButton onClick={() => setShowDebugInfo(!showDebugInfo)}>
        <Construction />
      </IconButton>
      {showDebugInfo && <DebugPanel />}
    </Box>
  );
};

const DebugPanel = () => {
  const { xlScreen, lgScreenOrUp, mdScreenOrUp, smScreenOrUp } = useScreenSize();
  const { height, width } = useWindowSizePx(100);
  const breakpoint = xlScreen
    ? 'xl'
    : lgScreenOrUp
    ? 'lg'
    : mdScreenOrUp
    ? 'md'
    : smScreenOrUp
    ? 'sm'
    : 'xs';

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 40,
        right: 20,
        zIndex: 9999,
        width: 200,
        p: 1,
        backgroundColor: 'black',
        color: 'white',
      }}
    >
      <table>
        <tr>
          <td>Breakpoint: </td>
          <td align='right'>{breakpoint}</td>
        </tr>
        <tr>
          <td>Height:</td>
          <td align='right'>{height} px</td>
        </tr>
        <tr>
          <td>Width:</td>
          <td align='right'>{width} px</td>
        </tr>
      </table>
    </Paper>
  );
};
