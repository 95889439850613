import { IconDefinition, IconName } from '@fortawesome/fontawesome-common-types';

export const faBatteryPack: IconDefinition = {
  prefix: 'fab',
  iconName: 'battery-pack' as IconName,
  icon: [
    512,
    512,
    [],
    'e001',
    'M 354 194 C 361 194 368 201 368 208 L 368 236 L 396 236 C 403 236 410 243 410 250 C 410 257.875 403 264 396 264 L 368 264 L 368 292 C 368 299.875 361 306 354 306 C 346.125 306 340 299.875 340 292 L 340 264 L 312 264 C 304.125 264 298 257.875 298 250 C 298 243 304.125 236 312 236 L 340 236 L 340 208 C 340 201 346.125 194 354 194 Z M 200 236 C 207 236 214 243 214 250 C 214 257.875 207 264 200 264 L 116 264 C 108.125 264 102 257.875 102 250 C 102 243 108.125 236 116 236 Z M 102 124 C 102 109.125 114.25 96 130 96 L 186 96 C 200.875 96 214 109.125 214 124 L 298 124 C 298 109.125 310.25 96 326 96 L 382 96 C 396.875 96 410 109.125 410 124 L 424 124 C 454.625 124 480 149.375 480 180 L 480 376 C 480 407.5 454.625 432 424 432 L 88 432 C 56.5 432 32 407.5 32 376 L 32 180 C 32 149.375 56.5 124 88 124 Z M 74 180 L 74 376 C 74 383.875 80.125 390 88 390 L 424 390 C 431 390 438 383.875 438 376 L 438 180 C 438 173 431 166 424 166 L 88 166 C 80.125 166 74 173 74 180 Z M 74 180',
  ],
};
