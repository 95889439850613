import { IconDefinition, IconName } from '@fortawesome/pro-regular-svg-icons';

export const faCrane: IconDefinition = {
  prefix: 'fab',
  iconName: 'graphql' as IconName,
  icon: [
    520,
    520,
    [],
    'f3b6',
    'M520.511,385.408c-5.485-27.046-29.263-46.485-56.859-46.485h-0.958c-27.597,0-51.374,19.439-56.859,46.485L359.879,612   h206.587L520.511,385.408z M463.172,429.577c-19.214,0-34.791-15.577-34.791-34.791c0-19.216,15.577-34.792,34.791-34.792   c19.216,0,34.792,15.576,34.792,34.792C497.964,414,482.388,429.577,463.172,429.577z M480.85,310.164   c-5.882-1.195-11.94-1.889-18.157-1.889c-42.005,0-78.552,29.873-86.903,71.04l-0.471,2.318L147.406,105.077   c15.603-10.441,26.312-26.81,29.452-45.436L480.85,310.164z M124.495,167.727V93.908c19.125-6.433,32.991-24.304,32.991-45.596   C157.486,21.63,135.856,0,109.175,0C82.494,0,60.863,21.63,60.863,48.312c0,21.289,13.862,39.159,32.981,45.594v87.281   c0,8.461,6.864,15.325,15.325,15.325c18.189,0,32.985,14.796,32.985,32.985c0,18.188-14.796,32.985-32.985,32.985   c-18.188,0-32.985-14.797-32.985-32.985c0-8.461-6.864-15.325-15.325-15.325s-15.325,6.864-15.325,15.325   c0,35.09,28.546,63.636,63.637,63.636c35.09,0,63.636-28.546,63.636-63.636C172.807,199.685,152.203,174.602,124.495,167.727z    M85.019,48.312c0-13.341,10.815-24.156,24.156-24.156c13.341,0,24.155,10.815,24.155,24.156s-10.814,24.156-24.155,24.156   C95.834,72.468,85.019,61.652,85.019,48.312z',
  ],
};
