// A link that renders as plain text if the user does not have access to the route

import { Link, LinkProps } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useRouteAccess } from 'routing/useRouteAccess';

export type AuthorizedLinkProps = NavLinkProps & LinkProps;

export const AuthorizedLink = (props: AuthorizedLinkProps) => {
  const hasAccess = useRouteAccess()(props.to as string);

  if (!hasAccess) {
    return <span>{props.children}</span>;
  }
  return <Link component={NavLink} {...props} />;
};
