import {
  faCalendar,
  faCalendarCheck,
  faCalendarExclamation,
  faQuestionCircle,
  IconDefinition,
  faCalendarTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '@mui/material';
import { ReservationBasicsFragment, ReservationStatus } from 'api/generated/graphql';
import { addDays } from 'date-fns';
import { RootRoutes } from 'enums/rootRoutes';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useTranslation } from 'react-i18next';

type ReservationStatusType = {
  color: string;
  contrastColor: string;
  icon: IconDefinition;
  name: string;
  description?: string;
};

// Returns true if the reservation is expiring in less than 7 days
export const isSoonToExpire = (expiryDate: Date | undefined) =>
  expiryDate && expiryDate < addDays(new Date(), 7) && expiryDate > new Date();

// Closed won is when the deal is signed by the external customer
export const isClosedWon = (zohoDealStage?: string) =>
  zohoDealStage && zohoDealStage.toUpperCase() === 'CLOSED WON';

export const getReservationUrl = (reservationId: string) =>
  `${RootRoutes.RESERVATIONS}/${reservationId}`;

// The reservation is not active if it is cancelled or allocated
export const isActiveReservation = (reservation: ReservationBasicsFragment) =>
  activeReservationStatuses.includes(reservation.status);

// The reservation is not active if it is cancelled or allocated
export const activeReservationStatuses = [ReservationStatus.Reserved, ReservationStatus.Expired];

export const allReservationStatuses = Object.values(ReservationStatus).reverse();

export const getReservationStatusIcon = (status: ReservationStatus | undefined) => {
  if (status === ReservationStatus.Reserved) return faCalendar;
  if (status === ReservationStatus.Allocated) return faCalendarCheck;
  if (status === ReservationStatus.Expired) return faCalendarExclamation;
  if (status === ReservationStatus.Cancelled) return faCalendarTimes;
  // Fallback for new statuses
  else return faQuestionCircle;
};

/*
 * Returns an object with both the native color code and the corresponding MUI color variable name
 */
export const useReservationStatusConfig = (
  status: ReservationStatus,
  zohoDealStage?: string
): ReservationStatusType => {
  const theme = useTheme();

  const { isInternal } = useCurrentUser();

  // We do not want to show the reservation as expired if the Zoho deal is flagged as closed won, even if the reservation has expired
  const showAsAccepted =
    !isInternal && activeReservationStatuses.includes(status) && isClosedWon(zohoDealStage);

  const { t } = useTranslation();

  const icon = getReservationStatusIcon(status);

  if (showAsAccepted)
    // Special case. The server does not know about this status, it's calculated in the frontend
    return {
      color: theme.palette.info.main,
      contrastColor: theme.palette.info.contrastText,
      icon: getReservationStatusIcon(ReservationStatus.Allocated),
      name: t('RESERVATIONS.STATUS_ACCEPTED'),
      description: t('RESERVATIONS.STATUS_ACCEPTED_DESCRIPTION'),
    };
  if (status === ReservationStatus.Reserved)
    return {
      color: theme.palette.info.main,
      contrastColor: theme.palette.info.contrastText,
      icon,
      name: t('RESERVATIONS.STATUS_RESERVED'),
      description: t('RESERVATIONS.STATUS_RESERVED_DESCRIPTION'),
    };
  if (status === ReservationStatus.Allocated)
    return {
      color: theme.palette.success.main,
      contrastColor: theme.palette.success.contrastText,
      icon,
      name: t('RESERVATIONS.STATUS_ALLOCATED'),
    };
  if (status === ReservationStatus.Expired)
    return {
      color: theme.palette.warning.main,
      contrastColor: theme.palette.warning.contrastText,
      icon,
      name: t('RESERVATIONS.STATUS_EXPIRED'),
      description: t('RESERVATIONS.STATUS_EXPIRED_DESCRIPTION'),
    };
  if (status === ReservationStatus.Cancelled)
    return {
      color: theme.palette.action.selected,
      contrastColor: theme.palette.text.primary,
      icon,
      name: t('RESERVATIONS.STATUS_CANCELLED'),
    };
  // Fallback for new statuses
  else
    return {
      color: theme.palette.grey[300],
      contrastColor: 'black',
      icon,
      name: status,
    };
};

// The number of days before reservation start or after reservation end that the user is allowed to reserve assets for.
export const reservableDaysOutsideReservation = 14;

export const getFirstPossibleReservationDate = (reservation: ReservationBasicsFragment) =>
  reservation.isVariationOrder
    ? undefined // Variation orders can be reserved at any time
    : addDays(new Date(reservation.startTime), -reservableDaysOutsideReservation);

export const getLastPossibleReservationDate = (reservation: ReservationBasicsFragment) =>
  reservation.isVariationOrder
    ? undefined // Variation orders can be reserved at any time
    : addDays(new Date(reservation.endTime), reservableDaysOutsideReservation);
