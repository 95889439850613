import { faCable } from '../../icons/customFontAwesome/faCable';
import { faCrane } from '../../icons/customFontAwesome/faCrane';
import { useTranslation } from 'react-i18next';

import {
  IconDefinition,
  faBoreHole,
  faExcavator,
  faHeat,
  faRoad,
  faTransformerBolt,
  faUserHardHat,
} from '@fortawesome/pro-regular-svg-icons';

export enum GenericAssetType {
  EARTH_MOVING = 'EARTH_MOVING',
  LIFTING = 'LIFTING',
  COMPACTION_AND_DRILLING = 'COMPACTION_AND_DRILLING',
  ROAD_AND_CONCRETE = 'ROAD_AND_CONCRETE',
  CABLE = 'CABLE',
  TRANSFORMER = 'TRANSFORMER',
  HEATING = 'HEATING',
  OTHER = 'OTHER',
}

type GenericAssetTypeConfig = {
  id: GenericAssetType;
  icon: IconDefinition;
  name: string;
};

const genericAssetIcons: Record<GenericAssetType, IconDefinition> = {
  [GenericAssetType.CABLE]: faCable,
  [GenericAssetType.HEATING]: faHeat,
  [GenericAssetType.LIFTING]: faCrane,
  [GenericAssetType.EARTH_MOVING]: faExcavator,
  [GenericAssetType.COMPACTION_AND_DRILLING]: faBoreHole,
  [GenericAssetType.ROAD_AND_CONCRETE]: faRoad,
  [GenericAssetType.TRANSFORMER]: faTransformerBolt,
  [GenericAssetType.OTHER]: faUserHardHat,
};

export const getGenericAssetIcon = (type: string): IconDefinition => {
  return genericAssetIcons[type as GenericAssetType] ?? faUserHardHat;
};

const useGenericAssetTypes = (): GenericAssetTypeConfig[] => {
  const { t } = useTranslation();
  return Object.values(GenericAssetType).map((id) => ({
    id,
    icon: genericAssetIcons[id],
    name: t(`GENERIC_ASSET.${id}`),
  }));
};

export default useGenericAssetTypes;
