import { IconDefinition, IconName } from '@fortawesome/fontawesome-common-types';

export const faFastCharger: IconDefinition = {
  prefix: 'far',
  iconName: 'fastcharger' as IconName,
  icon: [
    512,
    512,
    [],
    'e001',
    'M 174.125 186 L 210 186 C 215.25 186 220.5 190.375 223.125 195.625 C 224.875 200.875 223.125 207 218.75 211.375 L 134.75 281.375 C 129.5 284.875 122.5 285.75 117.25 281.375 C 112 277.875 110.25 270.875 112.875 265.625 L 133 214 L 98 214 C 91.875 214 86.625 210.5 84 205.25 C 82.25 200 84 193 88.375 189.5 L 172.375 119.5 C 177.625 116 184.625 115.125 189.875 119.5 C 195.125 123 196.875 130 194.25 135.25 Z M 28 438 L 28 88 C 28 57.375 52.5 32 84 32 L 224 32 C 254.625 32 280 57.375 280 88 L 280 256 L 287 256 C 329 256 364 291 364 333 L 364 357.5 C 364 371.5 374.5 382 388.5 382 C 401.625 382 413 371.5 413 357.5 L 413 267.375 C 384.125 258.625 364 231.5 364 200 L 364 165 C 364 153.625 372.75 144 385 144 L 392 144 L 392 102 C 392 95 398.125 88 406 88 C 413 88 420 95 420 102 L 420 144 L 448 144 L 448 102 C 448 95 454.125 88 462 88 C 469 88 476 95 476 102 L 476 144 L 483 144 C 494.375 144 504 153.625 504 165 L 504 200 C 504 231.5 483 258.625 455 267.375 L 455 357.5 C 455 394.25 424.375 424 388.5 424 C 351.75 424 322 394.25 322 357.5 L 322 333 C 322 313.75 306.25 298 287 298 L 280 298 L 280 438 L 287 438 C 298.375 438 308 447.625 308 459 C 308 471.25 298.375 480 287 480 L 21 480 C 8.75 480 0 471.25 0 459 C 0 447.625 8.75 438 21 438 Z M 70 88 L 70 438 L 238 438 L 238 88 C 238 81 231 74 224 74 L 84 74 C 76.125 74 70 81 70 88 Z M 434 228 C 448.875 228 462 215.75 462 200 L 462 186 L 406 186 L 406 200 C 406 215.75 418.25 228 434 228 Z M 434 228',
  ],
};
