import { AssetIdentifierFragment, AssetModelTypeIdentifierFragment } from 'api/generated/graphql';
import { getAssetBaseType } from './getAssetBaseType';
import { getAssetSubtype } from './getAssetSubtype';
import { AssetTypeConfig, ALL_ASSET_SUBTYPES } from './types';
import { IconDefinition, faQuestion } from '@fortawesome/pro-regular-svg-icons';
import { getGenericAssetIcon } from './useGenericAssetTypes';

export const getAssetTypeConfig = (
  assetModel: AssetModelTypeIdentifierFragment
): AssetTypeConfig => {
  const config = ALL_ASSET_SUBTYPES.find(
    (a) => a.type === getAssetBaseType(assetModel) && a.subtype === getAssetSubtype(assetModel)
  );

  if (!config) throw new Error('Invalid asset type');
  return config;
};

export const getAssetIcon = (asset: AssetIdentifierFragment): IconDefinition => {
  if (!asset.assetModel) return faQuestion;

  // If the asset is a generic asset, show the icon for the generic asset type
  if (asset.aspects.generic?.type) return getGenericAssetIcon(asset.aspects.generic.type);

  // Default to the asset type icon
  return getAssetTypeConfig(asset.assetModel).icon;
};
