import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  ListItem,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { SearchResult, useAppSearch } from './useAppSearch';
import { useAsyncDebounce } from 'react-table';
import { trim } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Search } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { FontAwesomeIconWrapper } from 'components/layout/FontAwesomeIconWrapper';
import { localStorageKeys } from 'utils/constants';
import { useScreenSize } from 'utils/hooks/useScreenSize';
import { WithPopover } from 'components/popovers/WithPopover';

export const AppSearchResponsive = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { smScreenOrUp } = useScreenSize();

  const location = useLocation();

  useEffect(() => {
    // Remove the popover on option click
    setAnchorEl(null);
  }, [location]);

  if (!smScreenOrUp)
    return (
      <WithPopover
        anchorEl={anchorEl}
        onAnchorElChanged={setAnchorEl}
        popoverContent={() => <AppSearch />}
        popoverSx={{ mt: 2, width: '100vw' }}
      >
        <IconButton
          color='primary'
          sx={{
            '&:active, &:hover': {
              bgcolor: (theme) => theme.palette.primary.dark,
              borderRadius: '50%',
            },
            bgcolor: (theme) => theme.palette.primary.dark,
            borderRadius: '50%',
            color: 'white',
          }}
        >
          <Search />
        </IconButton>
      </WithPopover>
    );

  return <AppSearch />;
};

// Component that search globally acrross the application with a dropdown-style search box
const AppSearch = () => {
  const debounceMs = 300;
  const visitedResultsLimit = 5; // Remember this many previously visited results

  const defaultWidthPx = 200; // Pixels when collapsed
  const fullWidthPx = 300; // Pixels when expanded

  const theme = useTheme();
  const { t } = useTranslation();
  const autocompleteRef = useRef<HTMLInputElement | null>(null);
  const { smScreenOrUp } = useScreenSize();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [focus, setFocus] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const { results, loading } = useAppSearch(debouncedSearchTerm);

  const debounced = useAsyncDebounce(() => {
    setDebouncedSearchTerm(trim(searchTerm));
  }, debounceMs);

  const [visitedResults, setVisitedResults] = useState<SearchResult[]>(() => {
    const storedJson = localStorage.getItem(localStorageKeys.appSearchHistory);
    return storedJson ? JSON.parse(storedJson) : [];
  });

  useEffect(() => {
    if (!smScreenOrUp)
      setTimeout(() => {
        if (autocompleteRef.current) autocompleteRef.current.click();
      }, 120);
  }, [smScreenOrUp]);

  useEffect(() => {
    localStorage.setItem(localStorageKeys.appSearchHistory, JSON.stringify(visitedResults));
  }, [visitedResults]);

  const emptyQuery = searchTerm === '';

  const options = emptyQuery ? visitedResults : results;

  return (
    <Autocomplete
      sx={{ '.MuiFormControl-root': { my: 0 } }}
      size='small'
      ref={autocompleteRef}
      open={focus}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
      filterOptions={(x) => x}
      options={options}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      blurOnSelect
      loading={loading}
      popupIcon={null}
      inputValue={searchTerm}
      value={null} // The component should not hold an actual value, only an input value and a list of options
      openOnFocus={false}
      autoHighlight
      groupBy={(option) => (emptyQuery ? t('SEARCH.HISTORY') : option.type.toString())}
      noOptionsText={
        <Typography variant='body2' color={grey[500]}>
          {searchTerm === '' ? t('SEARCH.HELPTEXT') : t('SEARCH.NO_RESULTS')}{' '}
        </Typography>
      }
      onChange={(event, newValue) => {
        // Navigate to search result
        setSearchTerm('');
        newValue &&
          setVisitedResults(
            [newValue, ...visitedResults.filter((r) => r.id !== newValue.id)].slice(
              0,
              visitedResultsLimit
            )
          ); // Update history
        newValue && newValue.url && navigate(newValue.url);
      }}
      onInputChange={(event, newInputValue) => {
        setSearchTerm(newInputValue);
        debounced();
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          placeholder={t('SEARCH.PLACEHOLDER')}
          InputProps={{
            ...params.InputProps,
            sx: { color: { xs: 'black', sm: 'white' }, zIndex: 999 },
            startAdornment: (
              <InputAdornment
                position='start'
                sx={{ mx: 1, color: 'white', display: { xs: 'none', sm: 'inherit' } }}
              >
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{
            background: 'rgba(255, 255, 255, 0.15)',
            borderRadius: 1,
            transition: theme.transitions.create('width'),
            width: { xs: '100vw', sm: focus ? fullWidthPx : defaultWidthPx }, // Stay small on mobile, animate expand on desktop
          }}
        />
      )}
      PaperComponent={({ children, ...other }) => (
        <Paper
          sx={{
            width: { xs: `calc(100vw -  35px)`, sm: fullWidthPx },
            ml: { xs: '18px', sm: 0 },
          }}
          {...other}
        >
          <Box>{children}</Box>
        </Paper>
      )}
      renderOption={(props, searchResult) => {
        const matches = match(searchResult.title, searchTerm);

        const parts = parse(searchResult.title, matches);
        return (
          <ListItem {...props} key={props.key}>
            <Box display='flex' alignItems='center'>
              <FontAwesomeIconWrapper icon={searchResult.icon} color={grey[600]} sx={{ mr: 1 }} />
              <Typography
                variant='body2'
                component='span'
                color='text.secondary'
                sx={{ wordWrap: 'break-word' }}
              >
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </span>
                ))}
              </Typography>
            </Box>
          </ListItem>
        );
      }}
    />
  );
};
