import { startOfDay } from 'date-fns';
import { DateRange } from 'types/dateTypes';

// Used to detect invalid date objects. Can happen if the string sent to the Date constructor is invalid (like new Date('99.99.2099'))
export const isValidDate = (d?: Date | null) => d instanceof Date && !isNaN(d.valueOf());

/**
 * Converts the given date to UTC.
 *
 * @param date - The date to convert to UTC. If no date is provided, returns `undefined`.
 * @returns The date in UTC. If no date is provided, returns `undefined`.
 */
export const toUtc = (date?: Date | null) => {
  return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000) : undefined;
};

export const isDateInTheFuture = (date: Date) => date > new Date();

// When for instance finding the distance in days between two dates, we want to use the start of the day as the reference point
// And it is important that it uses UTC midnight, not local midnight, since all dates are stored in UTC
export const utcStartOfToday = () => toUtc(startOfDay(new Date())) as Date; // Can't be undefined since we know the date is valid

// Takes in a date range 'xx.xx.xx - xx.xx.xx' that we use for displaying durations of projects/reservation/asset allocations
// And returns a object with startTime and endTime
// Used in tests so we do not have to fetch data for a specific asset, instead just get the Date's from string
export const parseDateRange = (dateRange: string) => {
  const pattern = /^\d{2}\.\d{2}\.\d{2} - \d{2}\.\d{2}\.\d{2}$/;

  if (!pattern.test(dateRange)) {
    throw new Error('Invalid date range format');
  }

  const [startStr, endStr] = dateRange.split(' - ');

  const startDateParts = startStr.split('.').map((part) => parseInt(part));
  const endDateParts = endStr.split('.').map((part) => parseInt(part));

  const startTime = new Date(2000 + startDateParts[2], startDateParts[0] - 1, startDateParts[1]);
  const endTime = new Date(2000 + endDateParts[2], endDateParts[0] - 1, endDateParts[1]);

  return { startTime, endTime };
};

export function dateRangesAreEqual(a: DateRange, b: DateRange) {
  return a.from.getTime() === b.from.getTime() && a.to.getTime() === b.to.getTime();
}
