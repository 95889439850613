import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import {
  PolicyDocumentSortableField,
  useCreateUserPolicyConsentsMutation,
  DocumentType,
  useListPolicyDocumentsQuery,
} from 'api/generated/graphql';
import { privacyPolicyUrl } from 'api/mocks/termsAndPolicy';
import { first } from 'lodash';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyPolicyUpdateBanner = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { updateCurrentUser } = useCurrentUser();

  const [createUserPolicyConsents] = useCreateUserPolicyConsentsMutation();

  const { data } = useListPolicyDocumentsQuery({
    variables: {
      filter: {
        documentTypes: [DocumentType.PrivacyPolicy],
        isPublished: true,
      },
      sort: {
        field: PolicyDocumentSortableField.Version,
        asc: false,
      },
      limit: 1,
    },
  });

  const latestPolicyDocument = first(data?.policyDocuments?.results);

  const saveConsents = (consent: boolean) => {
    latestPolicyDocument &&
      createUserPolicyConsents({
        variables: {
          input: [
            {
              policyDocumentId: latestPolicyDocument.id,
              optionalConsent: consent,
            },
          ],
        },
        onCompleted: (data) => {
          const updatedUser = first(data.createUserPolicyConsents)?.user;
          // TODO: Check CurrentUserContext.Provider to see if there is a way to update it automatically
          updatedUser && updateCurrentUser(updatedUser);
        },
      });
  };

  return (
    <Box
      sx={{ position: 'fixed', bottom: 16, left: 0, right: 0 }}
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          maxWidth: 680,
          background: theme.palette.secondary.dark,
          color: theme.palette.secondary.contrastText,
        }}
      >
        <Typography variant='h3'>
          <Trans
            i18nKey='PRIVACY_POLICY.UPDATE_BANNER_TITLE'
            components={{
              privacyPolicyLink: (
                <Link
                  href={privacyPolicyUrl}
                  target='_blank'
                  pl={0.5}
                  rel='noopener'
                  sx={{
                    color: theme.palette.secondary.contrastText,
                    textDecorationColor: theme.palette.secondary.contrastText,
                  }}
                />
              ),
            }}
          />
        </Typography>
        <Typography variant='body2' pt={2}>
          {t('PRIVACY_POLICY.UPDATE_BANNER_TEXT')}
        </Typography>
        <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
          <Button
            onClick={() => saveConsents(false)}
            sx={{ color: theme.palette.secondary.light, pr: 2 }}
          >
            {t('BUTTON_DECLINE')}
          </Button>
          <Button onClick={() => saveConsents(true)} sx={{ color: theme.palette.secondary.light }}>
            {t('BUTTON_ACCEPT')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
