import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faSwitchboardBase: IconDefinition = {
  prefix: 'fab', // Font Awesome Custom
  iconName: 'cabinet-filing',
  icon: [
    512,
    512,
    [],
    'e001',
    'M 253.300781 32 C 376.675781 32 477.300781 131.75 477.300781 256 C 477.300781 379.375 376.675781 480 253.300781 480 C 181.671875 480 118.1875 446.558594 77.257812 394.59375 C 70.757812 386.339844 72.472656 374.554688 80.460938 367.71875 C 90.132812 359.445312 104.855469 361.792969 112.957031 371.609375 C 146.320312 412.042969 196.773438 438 253.300781 438 C 353.050781 438 435.300781 355.75 435.300781 256 C 435.300781 155.375 353.050781 74 253.300781 74 C 168.78125 74 97.84375 131.410156 77.300781 209.414062 C 71.289062 232.246094 80.460938 235.078125 93.300781 235.078125 L 112.957031 235.078125 L 237.300781 183.652344 C 237.480469 152.320312 261.917969 128 293.300781 128 C 323.925781 128 349.300781 152.5 349.300781 184 C 349.300781 214.625 323.925781 240 293.300781 240 C 277.136719 240 262.8125 233.316406 252.699219 222.671875 L 167 252.613281 C 163.832031 253.71875 163.859375 258.203125 167.039062 259.273438 L 250.574219 287.339844 C 260.753906 275.417969 275.964844 268 293.300781 268 C 323.925781 268 349.300781 292.5 349.300781 324 C 349.300781 354.625 323.925781 380 293.300781 380 C 264.183594 380 241.050781 358.320312 237.714844 330.855469 L 112.957031 277.078125 L 77.300781 277.078125 C 45.300781 277.078125 20.097656 256.496094 37.902344 193.976562 C 64.625 100.160156 150.59375 32 253.300781 32 Z M 293.300781 303 C 281.050781 303 272.300781 311.75 272.300781 324 C 272.300781 335.375 281.050781 345 293.300781 345 C 304.675781 345 314.300781 335.375 314.300781 324 C 314.300781 311.75 304.675781 303 293.300781 303 Z M 293.300781 205 C 304.675781 205 314.300781 195.375 314.300781 184 C 314.300781 171.75 304.675781 163 293.300781 163 C 281.050781 163 272.300781 171.75 272.300781 184 C 272.300781 195.375 281.050781 205 293.300781 205 Z M 293.300781 205',
  ],
};
