import { PaletteOptions, createTheme } from '@mui/material';
import { themeBase } from './themeBase';

const lightPalette: PaletteOptions = {
  primary: {
    main: 'hsla(155, 41%, 34%, 1)',
    dark: 'hsla(178, 58%, 17%, 1)',
    light: 'hsla(142, 37%, 51%, 1)',
    contrastText: 'hsla(0, 0%, 100%, 1)',
  },
  secondary: {
    main: 'hsla(243, 98%, 65%, 1)',
    light: 'hsla(243, 97%, 72%, 1)',
    dark: 'hsla(218, 34%, 26%, 1)',
    contrastText: 'hsla(0, 0%, 100%, 1)',
  },
  background: {
    default: 'hsla(0, 0%, 98%, 1)',
    paper: 'hsla(0, 0%, 100%, 1)',
  },
  warning: {
    main: 'hsla(36, 79%, 36%, 1)',
    light: 'hsla(36, 79%, 50%, 1)',
    dark: 'hsla(36, 80%, 25%, 1)',
    contrastText: 'hsla(0, 0%, 100%, 1)',
  },
  info: {
    main: 'hsla(206, 90%, 43%, 1)',
    light: 'hsla(207, 68%, 54%, 1)',
    dark: 'hsla(207, 91%, 30%, 1)',
    contrastText: 'hsla(0, 0%, 100%, 1)',
  },
  success: {
    main: 'hsla(122, 39%, 37%, 1)',
    light: 'hsla(122, 39%, 49%, 1)',
    dark: 'hsla(122, 40%, 32%, 1)',
    contrastText: 'hsla(0, 0%, 100%, 1)',
  },
  error: {
    main: 'hsla(4, 80%, 46%, 1)',
    light: 'hsla(4, 93%, 65%, 1)',
    dark: 'hsla(4, 93%, 40%, 1)',
    contrastText: 'hsla(0, 0%, 100%, 1)',
  },
  text: {
    primary: 'hsla(0, 0%, 0%, 0.87)',
    secondary: 'hsla(0, 0%, 0%, 0.6)',
    disabled: 'hsla(0, 0%, 0%, 0.38)',
  },
  action: {
    active: 'hsla(0, 0%, 0%, 0.54)',
    hover: 'hsla(0, 0%, 0%, 0.04)',
    selected: 'hsla(0, 0%, 0%, 0.08)',
    disabled: 'hsla(0, 0%, 0%, 0.26)',
    disabledBackground: 'hsla(0, 0%, 0%, 0.12)',
    focus: 'hsla(0, 0%, 0%, 0.12)',
  },
  divider: 'hsla(0, 0%, 0%, 0.12)',
};

export const theme = createTheme({
  palette: {
    mode: 'light',
    ...lightPalette,
  },
  ...themeBase,
});
