import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faBatteryBase: IconDefinition = {
  prefix: 'fab', // Font Awesome Custom
  iconName: 'cabinet-filing',
  icon: [
    512,
    512,
    [],
    'e001',
    'M 480.003906 253.300781 C 480.003906 376.675781 380.253906 477.300781 256.003906 477.300781 C 132.628906 477.300781 32.003906 376.675781 32.003906 253.300781 C 32.003906 181.671875 65.445312 118.183594 117.40625 77.257812 C 125.664062 70.753906 137.453125 72.472656 144.285156 80.457031 C 152.558594 90.132812 150.210938 104.851562 140.394531 112.953125 C 99.960938 146.316406 74.003906 196.769531 74.003906 253.300781 C 74.003906 353.050781 156.253906 435.300781 256.003906 435.300781 C 356.628906 435.300781 438.003906 353.050781 438.003906 253.300781 C 438.003906 168.78125 380.59375 97.84375 302.589844 77.300781 C 279.757812 71.285156 276.925781 80.457031 276.925781 93.296875 L 276.925781 164.125 L 326.003906 164.125 C 337.378906 164.125 347.003906 173.75 347.003906 186 C 347.003906 197.375 337.378906 206.125 326.003906 206.125 L 276.925781 206.125 L 276.925781 212.484375 L 276.804688 212.484375 L 276.804688 254.125 C 276.804688 266.375 268.378906 276 257.003906 276 C 244.753906 276 234.882812 266.375 234.882812 254.125 L 234.882812 206.125 L 188.003906 206.125 C 175.753906 206.125 167.003906 197.375 167.003906 186 C 167.003906 173.75 175.753906 164.125 188.003906 164.125 L 234.882812 164.125 L 234.882812 96 C 234.882812 95.578125 234.898438 95.160156 234.925781 94.742188 L 234.925781 77.296875 C 234.925781 45.300781 255.507812 20.09375 318.027344 37.902344 C 411.84375 64.625 480.003906 150.589844 480.003906 253.300781 Z M 480.003906 253.300781 M 160.003906 330.125 C 160.003906 318.75 168.753906 309.125 181.003906 309.125 L 333.003906 309.125 C 344.378906 309.125 354.003906 318.75 354.003906 330.125 C 354.003906 342.375 344.378906 351.125 333.003906 351.125 L 181.003906 351.125 C 168.753906 351.125 160.003906 342.375 160.003906 330.125 Z M 160.003906 330.125',
  ],
};
