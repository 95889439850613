import { Avatar, AvatarProps, Tooltip, Typography, useTheme } from '@mui/material';
import { UserBasicsFragment } from 'api/generated/graphql';
import { useUserPicture } from 'providers/UserPictureProvider';
import { stringToColor } from 'utils/colors';
import { mergeSx } from 'utils/mui';
import { AvatarPlaceholder } from './AvatarPlaceholder';

type UserAvatarProps = AvatarProps & {
  user: Omit<UserBasicsFragment, '__typename'> | undefined | null; // We want to accept user objects that do not necessarilly come from the User graphql node.
  roleName?: string;
  withTooltip?: boolean;
  fontSize?: number;
  size?: number;
  imageOverride?: string;
};

export const UserAvatar = ({
  user,
  roleName,
  withTooltip = true,
  fontSize = 15,
  size = 40,
  ...rest
}: UserAvatarProps) => {
  const theme = useTheme();
  let userDisplayName = (user?.userName || user?.email) ?? '';

  if (roleName) userDisplayName = userDisplayName.concat(` (${roleName})`);

  const { getUserPicture } = useUserPicture();
  const picture = user ? getUserPicture(user) : null;

  const bgcolor = userDisplayName
    ? stringToColor(userDisplayName)
    : theme.palette.background.default;

  const width = size;
  const height = size;

  if (!user || (user?.email === null && user?.phone === null)) {
    return <AvatarPlaceholder width={width} height={height} />;
  }

  return (
    <Tooltip title={withTooltip ? userDisplayName : null} placement='bottom'>
      {picture ? (
        <Avatar
          alt='User profile picture'
          src={picture}
          {...rest}
          sx={mergeSx(
            {
              width,
              height,
            },
            rest.sx
          )}
        />
      ) : (
        <Avatar
          {...rest}
          sx={mergeSx(
            {
              bgcolor,
              width,
              height,
            },
            rest.sx
          )}
        >
          <Typography
            variant='h5'
            sx={{ color: (theme) => theme.palette.getContrastText(bgcolor), fontSize: fontSize }}
          >
            {userDisplayName?.substring(0, 2).toLocaleUpperCase()}
          </Typography>
        </Avatar>
      )}
    </Tooltip>
  );
};
