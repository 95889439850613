/**
 * Helper function to convert a color code from hex to rgb with an optional alpha channel for opacity.
 * @argument hex Hex string like #CCDDEE
 * @argument alpha opacity from 0 to 1
 * */
export const hexToRGB = (hex: string, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

/**
 * Helper function to generate a color based on the string we pass
 * @argument string
 * */
export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

// Convert "#RRGGBB" to "AARRGGBB" where alpha input is from 0 to 1 and is converted to 00 to FF
export const hexToArgb = (hex: string, alpha = 1) => {
  const hexValue = hex.replace('#', '');
  return (
    Math.round(alpha * 255)
      .toString(16)
      .toUpperCase()
      .padStart(2, '0') + hexValue
  );
};

export function hslaToHex(hsla: string, backgroundColor: string = '#ffffff'): string {
  // Extract HSLA values using regex
  const hslaRegex = /hsla\(\s*(\d+),\s*(\d+)%?,\s*(\d+)%?,\s*(\d*\.?\d+)\s*\)/;
  const match = hsla.match(hslaRegex);

  if (!match) {
    throw new Error('Invalid HSLA color format');
  }

  const h = Number(match[1]);
  const s = Number(match[2]);
  const l = Number(match[3]);
  const a = Number(match[4]);

  // Convert HSL to RGB
  const sRatio = s / 100;
  const lRatio = l / 100;

  const c = (1 - Math.abs(2 * lRatio - 1)) * sRatio;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = lRatio - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  // Convert background color to RGB
  const hexToRgb = (hex: string): [number, number, number] => {
    let hexClean = hex.replace('#', '');
    if (hexClean.length === 3) {
      hexClean = hexClean
        .split('')
        .map((c) => c + c)
        .join('');
    }
    const bigint = parseInt(hexClean, 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  const [bgR, bgG, bgB] = hexToRgb(backgroundColor);

  // Blend with background color
  const blendWithBackground = (color: number, bgColor: number, alpha: number): number => {
    return Math.round(color * alpha + bgColor * (1 - alpha));
  };

  r = blendWithBackground(r, bgR, a);
  g = blendWithBackground(g, bgG, a);
  b = blendWithBackground(b, bgB, a);

  // Convert RGB to HEX
  const hex = (r << 16) + (g << 8) + b;
  return `#${hex.toString(16).padStart(6, '0')}`;
}
