import { Button, ButtonProps, Link, LinkProps } from '@mui/material';
import { NavLink } from 'react-router-dom';

type LinkButtonProps = ButtonProps & {
  href: string;
  linkProps?: LinkProps;
};

export const LinkButton = ({ href, linkProps, ...props }: LinkButtonProps) => {
  return (
    <Link component={NavLink} to={href} {...linkProps}>
      <Button {...props}></Button>
    </Link>
  );
};
