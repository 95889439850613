import { OwnUserFragment } from 'api/generated/graphql';
import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { googleAnalyticsId } from 'utils/env';

/**
 * Custom hook for initializing Google Analytics
 * @param {OwnUserFragment | null} user - The current user object or null
 * @param {boolean} anonymize - Whether to anonymize the user data
 */

const useGoogleAnalytics = (user: OwnUserFragment | null, anonymize: boolean) => {
  // Track initialization status using useRef, which persists across renders
  const isInitialized = useRef(false);

  useEffect(() => {
    // Check if the Google Analytics ID is available
    if (!googleAnalyticsId) {
      // console.warn('Google Analytics ID not available');
      return;
    }

    // Prevent re-initialization if already initialized
    if (isInitialized.current) {
      // console.info('Google Analytics already initialized');
      return;
    }

    // Initialize Google Analytics once
    // console.info('Initializing Google Analytics with ID:', googleAnalyticsId);
    ReactGA.initialize(googleAnalyticsId);

    // Set user data in GA if user is available and not anonymized
    const userId = anonymize || !user ? 'Anonymous' : user.id;
    ReactGA.set({ userId });

    // console.info(`Google Analytics initialized with userId: ${userId}`);

    // Mark as initialized
    isInitialized.current = true;
  }, [user, anonymize]);

  // Optionally return anything if needed in future implementations
};

export default useGoogleAnalytics;
