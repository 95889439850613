import {
  OwnUserFragment,
  SpecialRoleType,
  UserBasicsFragment,
  UserDetailsFragment,
} from 'api/generated/graphql';
import { RootRoutes } from 'enums/rootRoutes';
import { locale } from 'utils/formats';

export const sortUsersByEmail = (
  user1?: UserBasicsFragment | null,
  user2?: UserBasicsFragment | null
) => {
  // Sorting by email because some users have their email as userName
  const email1 = user1?.email;
  const email2 = user2?.email;

  if (!email1 && !email2) return 0;
  if (!email1) return 1;
  if (!email2) return -1;

  return email1.localeCompare(email2, locale);
};

// Does the user have the admin role?
export const isAdmin = (user?: UserDetailsFragment | OwnUserFragment | null) => {
  return (
    user?.userRoles?.some((r) => r.role?.specialRole === SpecialRoleType.Administrator) === true
  );
};

export const getUserUrl = (userId: string) => `${RootRoutes.USERS}/${userId}`;
