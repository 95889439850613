import { Box } from '@mui/material';
import React, { ReactNode, Suspense, useEffect, useRef, useState } from 'react';
import { Navbar } from './Navbar/Navbar';
import { Page } from './Page';
import { Sidebar } from './Sidebar/Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import { MobileMenu } from './MobileMenu/MobileMenu';
import { useScreenSize } from 'utils/hooks/useScreenSize';
import { useSidebarCollapse } from 'utils/hooks/useSidebarCollapse';
import { useResizeDetector } from 'react-resize-detector';
import { PrivacyPolicyUpdateBanner } from 'components/alerts/PrivacyPolicyUpdateBanner';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LogoNavbar } from './Navbar/LogoNavbar';
import { DefaultPageContainer } from './DefaultPageContainer';
import { useRouteAccess } from 'routing/useRouteAccess';
import { RootRoutes } from 'enums/rootRoutes';
import TermsAndPolicyAcceptance from 'modules/termsAndPolicyAcceptance';
import Unauthorized from 'modules/errorPages/unauthorized';

export const SidebarOpenContext = React.createContext<boolean>(true);
export const useSidebarOpen = () => React.useContext(SidebarOpenContext);

// The layout (sidebar/app bar) for the application, including route authorization
export const ProtectedLayout = ({ noSidebar }: { noSidebar?: boolean }) => {
  const { sidebarOpen, setSidebarOpen } = useSidebarCollapse();
  const sidebarRef = useRef(null);

  const location = useLocation();
  const hasAccess = useRouteAccess();

  const { smScreenOrUp } = useScreenSize();
  const { currentUser } = useCurrentUser();

  const navbarHeight = 64;

  const { width } = useResizeDetector({ targetRef: sidebarRef });

  const [sidebarWidth, setSidebarWidth] = useState<number | null>(null);

  // Show banner when latestPrivacyPolicyAccepted === null (meaning user has not accepted or declined the latest PP version)
  const showPrivacyPolicyUpdateBanner = currentUser?.latestPrivacyPolicyAccepted === null;

  useEffect(() => {
    if (smScreenOrUp) {
      setSidebarWidth(width ?? 30);
    } else {
      setSidebarWidth(null);
    }
  }, [smScreenOrUp, width]);

  const isPublicRoute = publicRoutes.includes(location.pathname as RootRoutes);

  if (!currentUser) return null;

  // The user has not accepted the latest terms and conditions
  if (currentUser && currentUser.latestTermsAndConditionsAccepted === null && !isPublicRoute) {
    return <TermsAndPolicyAcceptance />;
  }

  if (noSidebar)
    return (
      <LayoutWithoutSidebar>
        {hasAccess(location.pathname) ? <Outlet /> : <Unauthorized />}
      </LayoutWithoutSidebar>
    );

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {smScreenOrUp && (
        <Box ref={sidebarRef}>
          <Sidebar
            open={sidebarOpen}
            onBurgerToggle={() => {
              // TODO: Decide if replace that with a solution similar to the one used in ProjectAssignmentsChart.tsx
              window.dispatchEvent(new Event('resize'));
              setSidebarOpen(!sidebarOpen);
            }}
          />
        </Box>
      )}

      <Box sx={{ flexDirection: 'column', display: 'flex', height: '100%', width: '100%' }}>
        <SidebarOpenContext.Provider value={sidebarOpen}>
          <Navbar navbarHeight={navbarHeight} sidebarWidth={sidebarWidth} />
          <Page navbarHeight={navbarHeight} sidebarWidth={sidebarWidth}>
            <DefaultPageContainer>
              <Suspense fallback=''>
                {hasAccess(location.pathname) ? <Outlet /> : <Unauthorized />}
              </Suspense>
            </DefaultPageContainer>
            {!smScreenOrUp && <MobileMenu />}
          </Page>
        </SidebarOpenContext.Provider>
      </Box>

      {showPrivacyPolicyUpdateBanner && <PrivacyPolicyUpdateBanner />}
    </Box>
  );
};

const LayoutWithoutSidebar = ({ children }: { children: ReactNode }) => {
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ flexDirection: 'column', display: 'flex', height: '100%', width: '100%' }}>
        <LogoNavbar />
        <Box py={5}>{children}</Box>
      </Box>
    </Box>
  );
};

const publicRoutes = [RootRoutes.POLICIES];
