import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import CommentIcon from '@mui/icons-material/Comment';
import { Logout } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
// import { GB, NO } from 'country-flag-icons/react/3x2';
import { NavLink, useNavigate } from 'react-router-dom';
import { RootRoutes } from 'enums/rootRoutes';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { privacyPolicyUrl, termsAndConditionsUrl } from 'api/mocks/termsAndPolicy';
import { useContext, useState } from 'react';
import { WithPopover } from 'components/popovers/WithPopover';
import { PermissionSimulator } from 'components/devTools/PermissionSimulator';
import { buildVersion } from 'utils/env';
import { useDialog } from 'providers/DialogProvider';
import { FeedbackDialog } from 'components/dialogs/FeedbackDialog';
import { useScreenSize } from 'utils/hooks/useScreenSize';
import { UserAvatar } from 'components/dataVisualization/UserAvatar';
import { CustomThemeContext } from 'providers/CustomThemeProvider';
import {
  Button,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  Box,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { Permission, RoleAssignmentType } from 'api/generated/graphql';

export const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { mdScreenOrUp } = useScreenSize();
  const { logout, currentUser, isInternal, isDeveloper, isAdmin, hasPermission } = useCurrentUser();
  // const { i18n, t } = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { darkMode, setDarkMode } = useContext(CustomThemeContext);
  const [openDialog, closeDialog] = useDialog();

  const openFeedbackDialog = () => {
    openDialog({
      children: <FeedbackDialog onClose={closeDialog} />,
    });
  };

  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      {mdScreenOrUp && (
        <Typography color='primary.contrast'>
          {isInternal || !currentUser?.organization
            ? currentUser?.userName
            : `${currentUser.organization.name} | ${currentUser?.userName}`}
        </Typography>
      )}
      <WithPopover
        anchorEl={anchorEl}
        onAnchorElChanged={setAnchorEl}
        popoverContent={() => (
          <Paper sx={{ p: 2 }}>
            <>
              <Stack direction='row' spacing={2} mb={2}>
                {currentUser && (
                  <UserAvatar
                    user={{
                      id: currentUser.id,
                      userName: currentUser?.userName,
                      email: currentUser?.email,
                      hasPicture: currentUser?.hasPicture ?? false,
                    }}
                    withTooltip={false}
                  />
                )}
                <Box>
                  <Typography variant='subtitle2'>{currentUser?.userName}</Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {currentUser?.email}
                  </Typography>

                  {currentUser?.phone}
                </Box>
              </Stack>
              {/* <Divider sx={{ my: 2 }} />
              <Typography variant='body2'>{t('USER.MENU.LANGUAGE')}</Typography>
              <Stack mt={1} spacing={2} direction='row'>
                <Button
                  onClick={() => i18n.changeLanguage('en')}
                  startIcon={<GB style={{ height: 12 }} />}
                  disabled={i18n.language === 'en'}
                >
                  {t('USER.MENU.LANGUAGE_ENGLISH')}
                </Button>
                <Button
                  disabled={i18n.language === 'no'}
                  onClick={() => i18n.changeLanguage('no')}
                  startIcon={<NO style={{ height: 12 }} />}
                >
                  Norsk
                </Button>
              </Stack> */}
              <Divider sx={{ my: 2 }} />

              <FormControlLabel
                sx={{ my: 1, mx: 0 }}
                control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
                label={'Dark mode'}
              />
              <Divider sx={{ my: 2 }} />

              <Box display='flex' flexDirection='column' alignItems='flex-start'>
                <Button
                  onClick={() => {
                    navigate(RootRoutes.OWN_PROFILE);
                    setAnchorEl(null);
                  }}
                  startIcon={<AccountCircleRoundedIcon />}
                  sx={{ mb: 2 }}
                >
                  {t('USER.PROFILE')}
                </Button>
                <Button
                  startIcon={<CommentIcon />}
                  sx={{ mb: 2 }}
                  onClick={() => {
                    setAnchorEl(null);
                    openFeedbackDialog();
                  }}
                >
                  {t('SEND_FEEDBACK')}
                </Button>

                <Button
                  startIcon={<Logout />}
                  onClick={() => {
                    logout({ logoutParams: { returnTo: window.location.origin } });
                  }}
                  color='error'
                >
                  {t('LOGIN.LOG_OUT')}
                </Button>

                {/* Just show for admins */}
                {isDeveloper || isAdmin ? <PermissionSimulator /> : undefined}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box>
                <Button
                  onClick={() => navigate(privacyPolicyUrl)}
                  sx={{ textDecoration: 'underline' }}
                >
                  {t('PRIVACY_POLICY')}
                </Button>

                <Button
                  onClick={() => navigate(termsAndConditionsUrl)}
                  sx={{ textDecoration: 'underline' }}
                >
                  {t('TERMS_AND_CONDITIONS')}
                </Button>
              </Box>
              {hasPermission(Permission.WritePolicyDocument) === RoleAssignmentType.UserRole && (
                <Box pt={1}>
                  <Button
                    onClick={() => {
                      navigate(RootRoutes.POLICY_ADMIN);
                      setAnchorEl(null);
                    }}
                    sx={{ textDecoration: 'underline' }}
                  >
                    {t('POLICIES.MANAGE')}
                  </Button>
                </Box>
              )}
              <Link
                to='/licenses.txt'
                target='_blank'
                rel='noopener noreferrer'
                component={NavLink}
                sx={{ fontSize: 14, ml: 0.5 }}
              >
                {t('USER.MENU.LICENSES')}
              </Link>
              <Typography
                sx={{ ml: 0.5, mt: 0.5, textAlign: 'right', fontSize: '.8rem' }}
                color='text.disabled'
                variant='subtitle1'
              >
                {t('APP_VERSION')}: {buildVersion}
              </Typography>
            </>
          </Paper>
        )}
      >
        <IconButton id='user-menu-button' aria-label='account of current user' aria-haspopup='true'>
          {currentUser && (
            <UserAvatar
              user={{
                id: currentUser.id,
                userName: currentUser?.userName,
                email: currentUser?.email,
                hasPicture: currentUser?.hasPicture ?? false,
              }}
              withTooltip={false}
            />
          )}
        </IconButton>
      </WithPopover>
    </Stack>
  );
};
