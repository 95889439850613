import { ExpandMore } from '@mui/icons-material';
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  OrganizationForUserFragment,
  OwnUserRoleFragment,
  Permission,
  RoleAssignmentType,
} from 'api/generated/graphql';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { OrganizationSelect } from 'components/inputs/OrganizationSelect';
import { useTranslation } from 'react-i18next';
import { RolesSelect } from 'modules/projectDetails/settingsTab/RolesSelect';
import { isDefined } from 'api/typeguards/isDefined';
import { uniqueId } from 'lodash';

export const defaultInternalPermissions = Object.values(Permission).map((p) => p as Permission);

/**
 * This is an admin feature that allows you to toggle permissions for the current user.
 * Useful for testing permissions in the app, and for making new roles
 * YES, it is kind of hacky, but it's supposed to be like that!
 */
export const PermissionSimulator = () => {
  const { t } = useTranslation();
  const {
    currentUser,
    updateCurrentUser,
    globalPermissions,
    organizationPermissions,
    projectPermissions,
  } = useCurrentUser();
  const allPermissions = Object.values(Permission);

  if (!currentUser) return null;

  const { organization } = currentUser;
  if (!organization) return null;

  const isInternal = organization.isInternal;

  return (
    <Accordion sx={{ boxShadow: 'none' }}>
      <AccordionSummary
        id='permission-simulator-accordion'
        expandIcon={<ExpandMore />}
        sx={{ px: 0 }}
      >
        <Typography variant='h5'>{t('USER.PERMISSION_SIMULATOR')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box mb={1}>
          <OrganizationSelect
            value={currentUser?.organization?.id}
            onChange={(org) =>
              updateCurrentUser({
                ...currentUser,
                organization: org,
              })
            }
          />
          <RolesSelect
            roles={
              (isInternal
                ? currentUser.userRoles?.map((r) => r.role)?.filter(isDefined)
                : currentUser.userOrganizationRoles?.map((r) => r.role)
              )?.filter(isDefined) ?? []
            }
            type='multiple'
            category={
              isInternal ? RoleAssignmentType.UserRole : RoleAssignmentType.UserOrganizationRole
            }
            onChange={(roles) => {
              updateCurrentUser({
                ...currentUser,
                userRoles: isInternal ? roles.map((r) => ({ role: r, id: uniqueId() })) : undefined,
                userOrganizationRoles: isInternal
                  ? undefined
                  : roles.map((r) => ({ role: r, id: uniqueId() })),
              });
            }}
          />
        </Box>
        <Typography variant='caption' color='text.secondary' sx={{ ml: 0.5, wordSpacing: 10 }}>
          Glob Org Proj
        </Typography>
        <List>
          {allPermissions.map((permission) => {
            return (
              <ListItem key={permission} sx={{ p: 0 }}>
                <Checkbox
                  sx={{ py: 0 }}
                  checked={globalPermissions.includes(permission as Permission)}
                  inputProps={{ 'aria-labelledby': permission }}
                  onChange={(e) => {
                    const addPermission = e.target.checked;

                    let newRoles: OwnUserRoleFragment[] = [];

                    if (addPermission) {
                      // Add a role with the permission to the userRoles
                      if (!currentUser.userRoles?.length) {
                        newRoles = [
                          {
                            role: {
                              id: uniqueId(),
                              name: 'Simulated role',
                              description: 'Simulated role',
                              assignableTo: [RoleAssignmentType.UserRole],
                              permissions: [permission],
                            },
                            id: uniqueId(),
                          },
                        ];
                      } else {
                        newRoles = currentUser.userRoles
                          .map((r) => {
                            if (r.role && r.role.id) {
                              return {
                                id: r.id!,
                                role: {
                                  ...r.role,
                                  permissions: [...r.role.permissions, permission],
                                },
                              };
                            }
                          })
                          .filter(isDefined);
                      }
                    } else {
                      // Remove the permission from all userRoles
                      newRoles =
                        currentUser.userRoles
                          ?.map((r) => {
                            if (r.role && r.role.id) {
                              return {
                                id: r.id!,
                                role: {
                                  ...r.role,
                                  permissions: r.role.permissions
                                    .filter((p) => p !== permission)
                                    .filter(isDefined),
                                },
                              };
                            }
                          })
                          .filter(isDefined) ?? [];
                    }

                    updateCurrentUser({
                      ...currentUser,
                      userRoles: newRoles,
                    });
                  }}
                />
                <Checkbox
                  sx={{ py: 0 }}
                  checked={organizationPermissions.includes(permission as Permission)}
                  inputProps={{ 'aria-labelledby': permission }}
                  onChange={(e) => {
                    const addPermission = e.target.checked;

                    let newRoles: OrganizationForUserFragment[] = [];

                    if (addPermission) {
                      // Add a role with the permission to the userRoles
                      if (!currentUser.userOrganizationRoles?.length) {
                        newRoles = [
                          {
                            role: {
                              id: uniqueId(),
                              name: 'Simulated role',
                              description: 'Simulated role',
                              assignableTo: [RoleAssignmentType.UserOrganizationRole],
                              permissions: [permission],
                            },
                            id: uniqueId(),
                          },
                        ];
                      } else {
                        newRoles = currentUser.userOrganizationRoles
                          .map((r) => {
                            if (r.role && r.role.id) {
                              return {
                                id: r.id!,
                                role: {
                                  ...r.role,
                                  permissions: [...r.role.permissions, permission],
                                },
                              };
                            }
                          })
                          .filter(isDefined);
                      }
                    } else {
                      // Remove the permission from all userRoles
                      newRoles =
                        currentUser.userOrganizationRoles
                          ?.map((r) => {
                            if (r.role && r.role.id) {
                              return {
                                id: r.id!,
                                role: {
                                  ...r.role,
                                  permissions: r.role.permissions
                                    .filter((p) => p !== permission)
                                    .filter(isDefined),
                                },
                              };
                            }
                          })
                          .filter(isDefined) ?? [];
                    }

                    updateCurrentUser({
                      ...currentUser,
                      userOrganizationRoles: newRoles,
                    });
                  }}
                />
                <Checkbox
                  sx={{ py: 0 }}
                  checked={projectPermissions.includes(permission as Permission)}
                  inputProps={{ 'aria-labelledby': permission }}
                  disabled
                  title={'Has permission in at least one project role'}
                />
                <ListItemText id={permission} primary={permission} />
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
