import { IconDefinition, IconName } from '@fortawesome/fontawesome-common-types';

export const faGraphql: IconDefinition = {
  prefix: 'fab',
  iconName: 'graphql' as IconName,
  icon: [
    24,
    24,
    [],
    'f3b6',
    'm12.731 2.751 4.935 2.849c.39-.41.94-.664 1.549-.664 1.181 0 2.138.957 2.138 2.138 0 1.001-.688 1.842-1.617 2.074l-.015.003v5.7c.949.233 1.642 1.076 1.642 2.081 0 1.182-.958 2.14-2.14 2.14-.637 0-1.208-.278-1.6-.719l-.002-.002-4.905 2.832c.069.202.109.434.109.675 0 1.182-.958 2.14-2.14 2.14s-2.14-.958-2.14-2.14c0-.216.032-.425.092-.621l-.004.015-4.941-2.844c-.39.407-.939.66-1.546.66-1.182 0-2.14-.958-2.14-2.14 0-1.002.689-1.844 1.619-2.076l.015-.003v-5.699c-.951-.231-1.646-1.076-1.646-2.082 0-1.182.958-2.14 2.14-2.14.396 0 .768.108 1.086.296l-.01-.005c.184.106.342.231.479.376l.001.001 4.938-2.85c-.056-.182-.088-.391-.088-.608 0-1.181.958-2.139 2.139-2.139s2.139.958 2.139 2.139c0 .219-.033.43-.094.629l.004-.015zm-.515.877c-.019.021-.037.039-.057.057l-.001.001 6.461 11.19c.026-.009.056-.016.082-.023v-5.707c-.938-.238-1.621-1.076-1.621-2.072 0-.183.023-.361.066-.531l-.003.015c.006-.024.012-.049.019-.072zm-3.015.059-.06-.06-4.946 2.852c.053.177.084.381.084.592 0 .969-.645 1.787-1.53 2.049l-.015.004-.076.021v5.708l.084.023 6.461-11.19zm2.076.507c-.179.053-.384.084-.596.084s-.417-.031-.611-.088l.015.004-6.46 11.189c.286.276.496.629.597 1.026l.003.015h12.911c.102-.413.313-.768.599-1.043l.001-.001-6.456-11.186zm.986 16.227 4.917-2.838c-.015-.047-.027-.094-.038-.142h-12.92l-.021.083 4.939 2.852c.39-.403.936-.653 1.54-.653.626 0 1.189.268 1.581.696l.001.002z',
  ],
};
