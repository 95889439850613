import { grey } from '@mui/material/colors';
import { SidebarLink } from './SidebarLink';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { RootRoutes } from 'enums/rootRoutes';
import {
  Drawer as MuiDrawer,
  Typography,
  IconButton,
  List,
  Link,
  ListItem,
  Box,
  lighten,
  darken,
  SvgIconProps,
  Button,
} from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import CommentIcon from '@mui/icons-material/Comment';
import { EvinyLogoIcon } from 'icons/EvinyLogoIcon';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useMenuOptions } from './useMenuOptions';
import { FeedbackDialog } from 'components/dialogs/FeedbackDialog';
import { useDialog } from 'providers/DialogProvider';
import { ComponentType } from 'react';
import { useRouteAccess } from 'routing/useRouteAccess';
import { PowersiteLogoFull } from 'icons/PowersiteLogoFull';

type SidebarProps = {
  open: boolean;
  onBurgerToggle: () => void;
};

const drawerWidth = 240;
const drawerWidthCollapsed = 58;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  borderRight: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderRight: 'none',
  overflowX: 'hidden',
  width: drawerWidthCollapsed,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.sidebar?.color,
  background: theme.sidebar?.background,
  borderRadius: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  padding: theme.spacing(2),
  '&:hover': {
    background: theme.sidebar?.header.background,
  },
}));

const SidebarButton = ({
  open,
  icon: IconComponent,
  label,
  onClick,
}: {
  open: boolean;
  icon: ComponentType<SvgIconProps>;
  label: string;
  onClick: () => void;
}) => (
  <IconButtonStyled onClick={onClick}>
    <IconComponent sx={{ pr: 1 }} />
    {open && <Typography variant='h5'>{label}</Typography>}
  </IconButtonStyled>
);

export const Sidebar = ({ open, onBurgerToggle }: SidebarProps) => {
  const { t } = useTranslation();
  const menuGroupedOptions = useMenuOptions();
  const [openDialog, closeDialog] = useDialog();
  const hasRouteAccess = useRouteAccess();

  const openFeedbackDialog = () => {
    openDialog({
      children: <FeedbackDialog onClose={closeDialog} />,
    });
  };

  return (
    <Drawer variant='permanent' open={open}>
      <Box
        sx={{
          color: grey[200],
          background: (theme) => theme.sidebar?.header.background,
          pl: '14px',
          pt: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Link component={NavLink} to={RootRoutes.HOME} aria-label='eviny logo'>
          {open ? <PowersiteLogoFull sx={{ width: 140 }} /> : <EvinyLogoIcon />}
        </Link>
      </Box>
      <List
        data-testid='sidebar-list'
        sx={{
          color: (theme) => theme.sidebar?.color,
          background: (theme) => theme.sidebar?.background,
          height: '100%',
          pt: 0,
          overflowY: 'auto',
          overflowX: 'hidden',
          '::-webkit-scrollbar': {
            width: 8,
            background: (theme) =>
              theme.sidebar ? lighten(theme.sidebar.header.background, 0.1) : undefined,
            borderRadius: 10,
          },
          '::-webkit-scrollbar-thumb': {
            background: (theme) =>
              theme.sidebar ? darken(theme.sidebar.header.background, 0.5) : undefined,
            borderRadius: 10,
          },
        }}
      >
        {menuGroupedOptions.map((groupedOpt) => (
          <ListItem
            key={groupedOpt.groupName}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: 0,
              width: '100%',
            }}
          >
            <Typography
              variant='h5'
              sx={{
                color: 'primary.light',
                fontSize: 14,
                pl: 1,
                py: 1,
                opacity: open ? '1' : '0',
              }}
            >
              {groupedOpt.groupName}
            </Typography>
            {groupedOpt.menuOptions
              .filter((opt) => hasRouteAccess(opt.href))
              .map((opt) => (
                <SidebarLink
                  href={opt.href}
                  icon={opt.icon}
                  key={opt.title}
                  title={opt.title}
                  disabled={opt.disabled}
                  hasTooltip={!open}
                />
              ))}
          </ListItem>
        ))}
      </List>

      <Box
        sx={{
          backgroundColor: (theme) => theme.sidebar?.background,
          py: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant='contained'
          sx={{
            minWidth: 0,
            backgroundColor: (theme) => theme.palette.secondary.main,
            '&:hover': {
              background: (theme) => theme.palette.secondary.light,
            },
          }}
          onClick={openFeedbackDialog}
        >
          <CommentIcon fontSize='small' sx={{ pr: open ? 0.5 : 0 }} />
          {open && t('SEND_FEEDBACK')}
        </Button>
      </Box>

      <SidebarButton
        open={open}
        icon={open ? ArrowBackIos : ArrowForwardIos}
        label={t('SIDEBAR.COLLAPSE')}
        onClick={onBurgerToggle}
      />
    </Drawer>
  );
};
