import { SvgIcon, useTheme } from '@mui/material';
import { EvinyLogoFullPink } from 'theme/pinkTheme';
import { WithSx, mergeSx } from 'utils/mui';

export const PowersiteLogoBlack = ({ sx }: WithSx) => {
  const theme = useTheme();
  if (theme.id === 'pink') return <EvinyLogoFullPink />; // Easter egg

  return (
    <SvgIcon viewBox='0 0 84 32' sx={mergeSx({ width: 84, height: 32 }, sx)}>
      <svg
        width='1509'
        height='357'
        viewBox='0 0 1509 357'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M284.922 244.678C295.021 224.281 299.436 201.063 296.911 178.428C289.347 114.38 236.356 58.6819 173.641 45.7921C159.897 42.9913 145.664 42.4759 131.661 42.7935C84.4485 43.7566 35.6746 63.7941 20.9575 112.344C-8.36199 208.679 106.344 367.771 212.274 321.465C245.482 306.691 269.132 276.632 284.922 244.678ZM288.238 246.359C269.955 280.979 247.059 317.534 211.54 336.64C177.509 355.178 137.871 350.535 104.934 331.929C49.1578 300.995 6.84439 232.184 0.68583 169.094C-4.50443 113.255 19.8122 66.5896 72.0168 44.0013C90.555 35.9686 110.4 31.2156 130.312 28.1545C152.64 24.7447 175.421 22.9538 197.77 27.7016C248.371 37.4783 291.346 78.3862 303.731 128.233C314.148 167.683 306.854 210.772 288.238 246.359Z'
          fill='#76E691'
        />
        <path
          d='M180.794 300.24C208.953 296.559 235.337 282.639 254.369 261.669C278.551 234.843 292.508 200.068 298.067 164.71C303.617 127.446 299.541 86.3927 276.411 55.4333C259.773 33.3084 235.368 19.5545 208.5 13.802C182.903 8.70541 156.155 13.2657 132.322 23.3756C87.9475 42.3874 47.9403 79.4064 35.7794 127.03C25.0396 167.427 34.5924 211.271 59.1902 244.906C87.099 284.075 132.338 307.174 180.794 300.24ZM181.263 303.931C109.411 312.843 36.5186 273.929 15.6014 202.275C6.54313 172.383 7.67281 139.555 18.5479 110.298C46.1391 38.3893 126.867 -10.3065 203.305 1.86489C259.435 11.756 305.288 51.4717 321.119 106.55C332.973 147.296 326.632 192.837 304.58 229.034C278.186 272.544 230.948 298.319 181.263 303.931Z'
          fill='#55B077'
        />
        <path
          d='M74.0317 291.135C106.043 319.247 146.487 336.171 189.43 333.553C260.652 329.987 315.126 269.047 310.758 197.679C309.514 175.804 302.887 154.194 293.215 134.579C262.302 68.6825 183.043 31.7988 114.929 60.6706C98.2963 67.6881 82.8921 77.8032 70.1325 90.594C37.601 123.011 23.6441 172.467 31.9162 217.456C37.044 245.88 52.24 272.217 74.0317 291.135ZM71.5589 293.915C30.5002 257.047 5.66302 198.949 17.6521 143.783C26.7989 99.772 58.7161 61.92 99.7279 43.9285C146.279 22.5583 203.616 28.1234 245.17 57.8022C267.201 73.1543 284.844 94.3839 297.109 118.128C327.266 174.674 337.647 249.691 299.701 305.144C282.772 329.836 256.248 347.9 226.918 354.111C168.055 367.219 113.201 331.892 71.5589 293.915Z'
          fill='#337A5C'
        />
        <path
          d='M471.626 121.174C488.199 121.174 502.086 127.11 513.304 138.989C524.531 150.869 530.149 165.584 530.149 183.135C530.149 200.686 524.531 215.348 513.304 227.149C502.086 238.958 488.199 244.849 471.626 244.849C453.982 244.849 439.902 238.174 429.377 224.815V289.101H405V123.499H429.377V141.2C439.893 127.841 453.982 121.166 471.626 121.166V121.174ZM467.219 222.261C477.902 222.261 486.829 218.536 494.027 211.086C501.234 203.627 504.833 194.31 504.833 183.126C504.833 171.942 501.234 162.396 494.027 154.946C486.829 147.487 477.893 143.754 467.219 143.754C456.545 143.754 447.381 147.487 440.174 154.946C432.976 162.396 429.386 171.793 429.386 183.126C429.386 194.46 432.976 203.627 440.174 211.086C447.381 218.536 456.396 222.261 467.219 222.261Z'
          fill='black'
        />
        <path
          d='M566.497 227.487C554.391 215.648 548.333 200.928 548.333 183.329C548.333 165.73 554.391 150.957 566.497 139.039C578.611 127.129 593.596 121.166 611.434 121.166C629.272 121.166 644.265 127.12 656.371 139.039C668.486 150.957 674.552 165.721 674.552 183.329C674.552 200.937 668.486 215.648 656.371 227.487C644.265 239.334 629.28 245.245 611.434 245.245C593.588 245.245 578.611 239.326 566.497 227.487ZM584.413 155.172C577.123 162.734 573.478 172.117 573.478 183.338C573.478 194.558 577.123 203.906 584.413 211.389C591.712 218.864 600.719 222.601 611.434 222.601C622.149 222.601 631.341 218.864 638.56 211.389C645.788 203.906 649.398 194.558 649.398 183.338C649.398 172.117 645.788 162.54 638.56 155.066C631.341 147.582 622.298 143.836 611.434 143.836C600.569 143.836 591.712 147.618 584.413 155.172Z'
          fill='black'
        />
        <path
          d='M825.613 217.394L854.661 123.305H878.856L838.616 242.036H811.652L782.587 158.156L753.522 242.036H726.54L686.318 123.305H712.122L741.187 217.394L770.947 123.305H795.836L825.596 217.394H825.613Z'
          fill='black'
        />
        <path
          d='M951.687 121.174C970.835 121.174 986.397 128.03 998.392 141.734C1010.39 155.446 1015.31 172.029 1013.13 191.501H914.318C916.022 201.158 920.456 208.986 927.619 214.985C934.782 220.984 943.429 223.988 953.568 223.988C961.34 223.988 968.309 222.08 974.456 218.263C980.612 214.446 985.408 209.26 988.826 202.713L1009.4 211.84C1004.1 221.965 996.537 230.066 986.724 236.136C976.912 242.215 965.615 245.245 952.853 245.245C934.95 245.245 919.926 239.326 907.773 227.487C895.629 215.648 889.552 200.928 889.552 183.329C889.552 165.73 895.505 150.957 907.42 139.039C919.326 127.129 934.084 121.166 951.696 121.166L951.687 121.174ZM951.687 142.432C942.66 142.432 934.835 145.206 928.211 150.736C921.595 156.258 917.197 163.468 915.015 172.347H988.358C986.336 163.468 982.008 156.258 975.384 150.736C968.768 145.206 960.872 142.432 951.687 142.432Z'
          fill='black'
        />
        <path
          d='M1060.77 123.365V140.322C1066.1 133.042 1072.91 127.694 1081.2 124.296C1089.5 120.88 1098.35 120.265 1107.76 122.425V145.661C1094.76 142.104 1083.67 143.22 1074.5 149.024C1065.34 154.828 1060.77 163.461 1060.77 174.911V242.036H1036.09V123.365H1060.77Z'
          fill='black'
        />
        <path
          d='M1118.48 211.36L1143.06 206.227C1145.25 219.003 1153.98 225.391 1169.29 225.391C1177.1 225.391 1183.38 223.88 1188.14 220.841C1192.9 217.801 1195.27 213.94 1195.27 209.266C1195.27 204.283 1192.77 200.51 1187.78 197.948C1182.79 195.368 1176.7 193.503 1169.52 192.337C1162.34 191.162 1155.12 189.722 1147.85 188.008C1140.59 186.293 1134.47 182.9 1129.48 177.846C1124.49 172.784 1121.99 165.962 1121.99 157.392C1121.99 146.948 1126.48 138.307 1135.45 131.459C1144.43 124.603 1155.71 121.166 1169.28 121.166C1182.85 121.166 1193.98 124.205 1202.64 130.275C1211.31 136.345 1216.5 144.607 1218.21 155.05L1193.62 160.184C1191.76 147.408 1183.64 141.019 1169.28 141.019C1162.72 141.019 1157.34 142.424 1153.12 145.234C1148.9 148.035 1146.79 151.693 1146.79 156.208C1146.79 160.255 1148.59 163.533 1152.18 166.015C1155.76 168.507 1160.29 170.151 1165.75 170.928C1171.22 171.706 1177.11 172.872 1183.43 174.427C1189.76 175.991 1195.64 177.74 1201.1 179.693C1206.57 181.646 1211.11 185.118 1214.69 190.093C1218.28 195.076 1220.07 201.314 1220.07 208.798C1220.07 219.233 1215.19 227.918 1205.43 234.846C1195.68 241.782 1183.62 245.245 1169.27 245.245C1154.92 245.245 1143.52 242.206 1134.61 236.136C1125.71 230.057 1120.33 221.804 1118.46 211.36H1118.48Z'
          fill='black'
        />
        <path
          d='M1241.64 99.8857C1238.68 96.9349 1237.19 93.3693 1237.19 89.1977C1237.19 85.0261 1238.67 81.4517 1241.64 78.5096C1244.61 75.5763 1248.17 74.1009 1252.28 74.1009C1256.4 74.1009 1259.9 75.5763 1262.8 78.5096C1265.69 81.4517 1267.14 85.0173 1267.14 89.1977C1267.14 93.3781 1265.69 96.9349 1262.8 99.8857C1259.9 102.828 1256.4 104.294 1252.28 104.294C1248.17 104.294 1244.61 102.828 1241.64 99.8857ZM1264.17 242.027H1240.16V123.343H1264.17V242.036V242.027Z'
          fill='black'
        />
        <path
          d='M1308.26 145.044H1283.18V123.481H1308.26V84.951L1332.42 77.3099V123.481H1365.55V145.044H1332.42V199.822C1332.42 207.085 1334.02 212.34 1337.24 215.595C1340.47 218.841 1345.6 220.455 1352.66 220.455H1365.55V242.036H1352.66C1337.48 242.036 1326.28 238.483 1319.06 231.36C1311.86 224.245 1308.25 213.34 1308.25 198.646V145.044H1308.26Z'
          fill='black'
        />
        <path
          d='M1445.87 121.174C1465.01 121.174 1480.58 128.03 1492.57 141.734C1504.56 155.446 1509.48 172.029 1507.31 191.501H1408.5C1410.2 201.158 1414.64 208.986 1421.8 214.985C1428.96 220.984 1437.61 223.988 1447.75 223.988C1455.52 223.988 1462.49 222.08 1468.64 218.263C1474.79 214.446 1479.59 209.26 1483.01 202.713L1503.58 211.84C1498.28 221.965 1490.72 230.066 1480.9 236.136C1471.09 242.215 1459.79 245.245 1447.03 245.245C1429.13 245.245 1414.11 239.326 1401.95 227.487C1389.81 215.648 1383.73 200.928 1383.73 183.329C1383.73 165.73 1389.68 150.957 1401.6 139.039C1413.5 127.129 1428.26 121.166 1445.87 121.166L1445.87 121.174ZM1445.87 142.432C1436.84 142.432 1429.01 145.206 1422.39 150.736C1415.77 156.258 1411.38 163.468 1409.19 172.347H1482.54C1480.51 163.468 1476.19 156.258 1469.56 150.736C1462.95 145.206 1455.05 142.432 1445.87 142.432Z'
          fill='black'
        />
      </svg>
    </SvgIcon>
  );
};
