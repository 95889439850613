import { useParams } from 'react-router-dom';
import {
  useListProjectIdentifierQuery,
  useListReservationIdentifierQuery,
  useListOrganizationsIdentifierQuery,
  useListUserDetailsQuery,
} from 'api/generated/graphql';
import { first } from 'lodash';
import { useRequiredParam } from 'api/hooks/useRequiredParam';

/*
 * This file contains the dynamic breadcrumbs for the routes.
 * The dynamic breadcrumbs are used to display the name of the asset, user, project, organization or reservation
 * To avoid having to do separate calls to the server, we use the cache-only fetch policy.
 * The breadcrumbs will initially be empty, and then update when the query is finished, and the cache is updated.
 */
export const DynamicAssetDetailsBreadcrumb = () => {
  const { id: externalId } = useParams();
  return <span>{externalId}</span>;
};

export const DynamicUserProfileBreadcrumb = () => {
  const id = useRequiredParam('id');
  const userQuery = useListUserDetailsQuery({
    variables: { userIds: [id] },
    fetchPolicy: 'cache-first',
  });
  const user = first(userQuery.data?.users?.results);
  return <span>{user?.userName}</span>;
};

export const DynamicProjectDetailsBreadcrumb = () => {
  const id = useRequiredParam('id');
  const projectQuery = useListProjectIdentifierQuery({
    variables: { ids: [id] },
    fetchPolicy: 'cache-only',
  });
  const project = first(projectQuery.data?.projects?.results);
  return <span>{project?.name}</span>;
};

export const DynamicOrganizationDetailsBreadcrumb = () => {
  const id = useRequiredParam('id');
  const organizationQuery = useListOrganizationsIdentifierQuery({
    variables: { ids: [id] },
    fetchPolicy: 'cache-only',
  });
  const organization = first(organizationQuery?.data?.organizations?.results);
  return <span>{organization?.name}</span>;
};

export const DynamicReservationDetailsBreadcrumb = () => {
  const id = useRequiredParam('id');
  const reservationQuery = useListReservationIdentifierQuery({
    variables: { ids: [id] },
    fetchPolicy: 'cache-only',
  });
  const reservation = first(reservationQuery.data?.reservations?.results);
  return <span>{reservation?.name}</span>;
};

export const DynamicTimelinesBreadcrumb = () => {
  const { type } = useParams();
  return <span style={{ textTransform: 'capitalize' }}>{type} timeline</span>;
};
