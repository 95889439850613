import { AssetType, AssetModelTypeIdentifierFragment } from 'api/generated/graphql';

export const getAssetBaseType = (assetModel: AssetModelTypeIdentifierFragment) => {
  if (assetModel.isCharger) return AssetType.Charger;
  if (assetModel.isSwitchboard) return AssetType.Switchboard;
  if (assetModel.isBatteryHub || assetModel.isBatteryPack) return AssetType.Battery;
  if (assetModel.isEnergyMeter) return AssetType.EnergyMeter;
  if (assetModel.isGeneric) return AssetType.Generic;
  return undefined;
};
