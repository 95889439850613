import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Feedback } from '@mui/icons-material';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';

interface ErrorBoundaryState {
  hasError?: boolean;
  eventId: string | null;
}

type WithAuth0PropsWithChildren = WithAuth0Props & {
  children: React.ReactNode;
};

class ErrorBoundary extends Component<WithAuth0PropsWithChildren, ErrorBoundaryState> {
  constructor(props: WithAuth0PropsWithChildren) {
    super(props);
    this.state = { eventId: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scope.setExtras(errorInfo as any);
      this.setState({ eventId: Sentry.captureException(error), hasError: true });
    });

    // Handle caching related issues with dynamic modules. Needs a page reload
    if (
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed') ||
      error.message.includes('error loading dynamically imported module')
    ) {
      window.location.reload();
    }
  }

  render() {
    const { user } = this.props.auth0;

    if (this.state.hasError) {
      return (
        <Box m={4}>
          <Typography variant='h3'>Oh no...</Typography>

          <Box mt={2} mb={2}>
            <Typography variant='subtitle1'>
              The application has crashed. This is unfortunate, but our developers have been
              automatically notified, and will try to fix it as soon as possible.
              <p>
                If you want to help us find the issue, it would be very helpful if you could briefly
                describe the circumstances for the crash in the feedback form.
              </p>
            </Typography>
          </Box>

          <Button
            variant='contained'
            startIcon={<Feedback />}
            color='primary'
            onClick={() =>
              Sentry.showReportDialog({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                eventId: this.state.eventId!,
                user: user ? { name: user.name, email: user.email } : undefined,
              })
            }
          >
            Report feedback
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default withAuth0(ErrorBoundary);

export const FallbackErrorBoundary = () => {
  return (
    <Box m={4}>
      <Typography variant='h3'>Oh no...</Typography>
      <Box mt={2} mb={2}>
        <Typography variant='subtitle1'>
          Something happened that made the application crash. Our developers have been automatically
          notified, and will try to fix this as soon as possible.
        </Typography>
      </Box>
    </Box>
  );
};
