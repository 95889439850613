import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface QueryParams {
  [key: string]: string | null;
}

type SetQueryParams = (params: Partial<QueryParams>) => void;

export const useQueryFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams: QueryParams = useMemo(() => {
    const params: QueryParams = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  }, [searchParams]);

  const setQueryParams: SetQueryParams = (params: Partial<QueryParams>) => {
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        for (const paramName in params) {
          const paramValue = params[paramName];
          if (paramValue) {
            newParams.set(paramName, paramValue);
          } else {
            newParams.delete(paramName);
          }
        }
        return newParams;
      },
      { replace: true }
    );
  };

  const deleteQueryParams = (paramNames: string[]) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      paramNames.forEach((paramName) => {
        newParams.delete(paramName);
      });
      return newParams;
    });
  };

  const clearQueryParams = () => {
    setSearchParams(() => new URLSearchParams());
  };

  const replaceQueryParams = (paramsToDelete: string[], paramsToAdd: Partial<QueryParams>) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);

      paramsToDelete.forEach((paramName) => {
        newParams.delete(paramName);
      });

      for (const paramName in paramsToAdd) {
        const paramValue = paramsToAdd[paramName];
        if (paramValue) {
          newParams.set(paramName, paramValue);
        } else {
          newParams.delete(paramName);
        }
      }

      return newParams;
    });
  };

  return { queryParams, setQueryParams, deleteQueryParams, clearQueryParams, replaceQueryParams };
};
