// Brand colors for Eviny

export const brandColors = {
  evinyGreen1: '#76e691',
  evinyGreen2: '#55b077',
  evinyGreen3: '#337a5c',
  evinyGreen4: '#124442',
  evinyCold1: '#e1c3ff',
  evinyCold2: '#554cfd',
  evinyCold3: '#2519ff',
  evinyCold4: '#2c3d5a',
  evinyWarm1: '#FCD300',
  evinyWarm2: '#fca50d',
  evinyWarm3: '#fc6d19',
  evinyWarm4: '#ed3f26',
  lightBackground: '#fafdfa',
  darkBackground: '#1b5b59',
  textPrimary: '#000000de',
  textSecondary: '#00000099',
  warningMain: '#ff9900',
  warningLight: '#ffb547',
  warningDark: '#c77700',
  warningBackground: 'rgba(255, 181, 71, 0.1)',
  infoMain: '#0B79D0',
  infoLight: '#3B93D9',
  infoDark: '#0860A6',
  infoBackground: 'rgba(11, 121, 208, 0.1)',
  successMain: '#4caf50',
  successLight: '#7bc67e',
  successDark: '#3b873e',
  errorMain: '#d22417',
  errorLight: '#f95e53',
  errorDark: '#c51407',
  errorBackground: '#fde9e8',
  reservationReservedBackground: '#edf7fe',
  reservationAllocatedBackground: '#f1f9f2',
  reservationExpiredBackground: '#fff7ec',
  greyBorder: '#C7C9C6',
  borderDivider: '#0000001f',
};
