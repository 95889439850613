import { Box, Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { useScreenSize } from 'utils/hooks/useScreenSize';
import { NavLink, useLocation, useMatches } from 'react-router-dom';
import { ReactElement } from 'react';

export const Breadcrumbs = () => {
  const { smScreenOrUp } = useScreenSize();

  const matches = useMatches();
  const location = useLocation();
  return (
    <MuiBreadcrumbs
      separator='›'
      aria-label='breadcrumbs'
      sx={{
        color: 'white',
        pl: {
          xs: 2,
          sm: 0,
        },
      }}
    >
      {matches.map((match) => {
        const handle = match.handle as { crumb: (data: unknown) => ReactElement | null };

        if (!handle?.crumb) return null;

        const crumb = handle.crumb(match.data);

        const isCurrent = match.pathname === location.pathname;

        return (
          <Box key={match.pathname}>
            {isCurrent ? (
              <Typography
                role='navigation'
                aria-label='breadcrumb previous location'
                variant='body1'
                textOverflow={{ xs: 'ellipsis', sm: 'unset' }}
                whiteSpace='nowrap'
                maxWidth={{ xs: 120, sm: 'unset' }}
                overflow='hidden'
              >
                {crumb}
              </Typography>
            ) : (
              <Link
                component={NavLink}
                to={match.pathname}
                sx={{
                  color: '#fff',
                }}
                role='navigation'
                aria-label='breadcrumb location'
              >
                {smScreenOrUp ? crumb : '...'}
              </Link>
            )}
          </Box>
        );
      })}
    </MuiBreadcrumbs>
  );
};
