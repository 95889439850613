import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIconWrapper } from 'components/layout/FontAwesomeIconWrapper';
import { Sx } from 'types/Sx';
import { organizationIcon } from './IconDefinitions';

type OrganizationIconProps = Omit<FontAwesomeIconProps, 'icon'> & {
  sx?: Sx;
};

export const OrganizationIcon = (props: OrganizationIconProps) => (
  <FontAwesomeIconWrapper {...props} icon={organizationIcon} />
);
