import { IconDefinition, IconName } from '@fortawesome/fontawesome-common-types';

export const faBatterySystem: IconDefinition = {
  prefix: 'fab',
  iconName: 'battery-system' as IconName,
  icon: [
    512,
    512,
    [],
    'e001',
    'M 224 427 C 224 439.25 214.375 448 203 448 C 190.75 448 182 439.25 182 427 C 182 338.625 109.375 266 21 266 C 8.75 266 0 257.25 0 245 C 0 233.625 8.75 224 21 224 C 133 224 224 315 224 427 Z M 0 420 C 0 405.125 12.25 392 28 392 C 42.875 392 56 405.125 56 420 C 56 435.75 42.875 448 28 448 C 12.25 448 0 435.75 0 420 Z M 0 329 C 0 317.625 8.75 308 21 308 C 86.625 308 140 361.375 140 427 C 140 439.25 130.375 448 119 448 C 106.75 448 98 439.25 98 427 C 98 385 63 350 21 350 C 8.75 350 0 341.25 0 329 Z M 0 329 M 368 192 C 368 185 361 178 354 178 C 346.125 178 340 185 340 192 L 340 220 L 312 220 C 304.125 220 298 227 298 234 C 298 241.875 304.125 248 312 248 L 340 248 L 340 276 C 340 283.875 346.125 290 354 290 C 361 290 368 283.875 368 276 L 368 248 L 396 248 C 403 248 410 241.875 410 234 C 410 227 403 220 396 220 L 368 220 Z M 368 192 M 214 234 C 214 227 207 220 200 220 L 140.527344 220 C 154.761719 227.898438 168.046875 237.304688 180.167969 248 L 200 248 C 207 248 214 241.875 214 234 Z M 214 234 M 252.070312 374 L 424 374 C 431 374 438 367.875 438 360 L 438 164 C 438 157 431 150 424 150 L 88 150 C 80.125 150 74 157 74 164 L 74 195.929688 C 60.394531 193.351562 46.355469 192 32 192 L 32 164 C 32 133.375 56.5 108 88 108 L 102 108 C 102 93.125 114.25 80 130 80 L 186 80 C 200.875 80 214 93.125 214 108 L 298 108 C 298 93.125 310.25 80 326 80 L 382 80 C 396.875 80 410 93.125 410 108 L 424 108 C 454.625 108 480 133.375 480 164 L 480 360 C 480 391.5 454.625 416 424 416 L 256 416 C 256 401.644531 254.648438 387.601562 252.070312 374 Z M 252.070312 374',
  ],
};
