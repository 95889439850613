import { useEffect, useState } from 'react';
import { baseApiUrl } from 'utils/env';

interface ApiVersionInfo {
  name: string;
  branchname: string;
  version: string;
  sha: string;
  build: string;
  fingerprint: string;
}

/**
 * The API exposes a version.json file that contains information about the current version of the API.
 * This hook fetches the version.json file and returns the information.
 * It also polls the version.json file every 2 minutes to keep the information up to date.
 * This is useful for determining if the API has been updated and if the client needs to be refreshed.
 * @returns The current API version information
 */
export const useApiVersionInfo = () => {
  const [apiVersion, setApiVersion] = useState<ApiVersionInfo | null>(null);
  const pollFrequencyMinutes = 2; // Poll every 2 minutes

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await fetch(`${baseApiUrl}/status/version`);
        const responseText = await response.text();
        const data: ApiVersionInfo = JSON.parse(responseText);
        if (data && data.fingerprint !== apiVersion?.fingerprint) setApiVersion(data); // Only update if the fingerprint has changed
      } catch (error) {
        console.error('Failed to fetch version.json:', error);
      }
    };

    // Fetch immediately
    fetchVersion();

    // Then fetch every n minutes
    const intervalId = setInterval(fetchVersion, pollFrequencyMinutes * 60 * 1000);

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [apiVersion?.fingerprint]);

  return apiVersion;
};
