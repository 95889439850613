import React from 'react';
import { UserBasicsFragment, useUpdateUsersMutation } from 'api/generated/graphql';
import { restApiUrl } from 'utils/env';

export const UserPictureContext = React.createContext<{
  updateUserPicture: (userId: string, picture: string | null) => void;
  getUserPicture: (user: Omit<UserBasicsFragment, '__typename'>) => string | null;
}>({
  updateUserPicture: () => {},
  getUserPicture: () => null,
});

export const UserPictureProvider = ({ children }: { children: React.ReactNode }) => {
  const [isPictureUploaded, setIsPictureUploaded] = React.useState(false);

  const getUserPicture = (user: Omit<UserBasicsFragment, '__typename'>) =>
    user.hasPicture || isPictureUploaded
      ? `${restApiUrl}/user/${user.id}/picture?${+isPictureUploaded}` // adding additional info to the url to force a refresh in order to be able to show in-sync images in the Navbar and user details page
      : null;

  const [updateUsers] = useUpdateUsersMutation({
    refetchQueries: ['ListUsers'],
    onCompleted: (data) => setIsPictureUploaded(data?.updateUsers?.[0]?.hasPicture ?? false),
  });

  const updateUserPicture = (userId: string, picture: string | null) => {
    updateUsers({
      variables: {
        input: [
          {
            id: userId,
            picture: picture,
          },
        ],
      },
    });
  };

  return (
    <UserPictureContext.Provider value={{ getUserPicture, updateUserPicture }}>
      {children}
    </UserPictureContext.Provider>
  );
};

export const useUserPicture = () => React.useContext(UserPictureContext);
