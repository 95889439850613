import { ThemeProvider, useMediaQuery } from '@mui/material';
import React, { createContext, useEffect, useState } from 'react';
import { theme as lightTheme } from 'theme/lightTheme';
import { theme as darkTheme } from 'theme/darkTheme';
import { pinkTheme } from 'theme/pinkTheme';

export const CustomThemeContext = createContext({
  currentTheme: 'light',
  setTheme: (theme: string) => console.log(theme),
  darkMode: false,
  setDarkMode: (darkMode: boolean) => console.log(darkMode),
});

const CustomThemeProvider = ({ children }: { children?: React.ReactNode }) => {
  // Use useMediaQuery to detect system preference
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Function to get theme from local storage or system preference
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme;
    } else {
      return prefersDarkMode ? 'dark' : 'light';
    }
  };

  // Initialize themeName state with the function
  const [themeName, setThemeName] = useState(getInitialTheme());

  // Update local storage whenever the themeName changes
  useEffect(() => {
    localStorage.setItem('theme', themeName);
  }, [themeName]);

  // Determine which theme to use based on themeName
  let theme;
  switch (themeName) {
    case 'dark':
      theme = darkTheme;
      break;
    case 'light':
      theme = lightTheme;
      // Apply the theme
      break;
    case 'pink':
      theme = pinkTheme;
      break;
    default:
      theme = lightTheme; // Default to lightTheme
  }

  // Context value to pass down to consumers
  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
    darkMode: themeName === 'dark',
    setDarkMode: (darkMode: boolean) => {
      setThemeName(darkMode ? 'dark' : 'light');
    },
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
