import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'utils/hooks/useDocumentTitle';

const Unauthorized = () => {
  const { t } = useTranslation();

  useDocumentTitle(t('UNAUTHORIZED.TITLE'));

  const navigate = useNavigate();

  return (
    <Box>
      <Typography variant='h2'>{t('UNAUTHORIZED.TITLE')}</Typography>
      <Typography
        variant='h5'
        sx={{
          py: 4,
        }}
      >
        {t('UNAUTHORIZED.BODY')}
      </Typography>
      <Button variant='contained' color='primary' onClick={() => navigate(-1)}>
        {t('GO_BACK')}
      </Button>
    </Box>
  );
};

export default Unauthorized;
