import { CssBaseline } from '@mui/material';
import { createContext, Suspense } from 'react';
import { DialogProvider } from './DialogProvider';
import { AuthProvider } from './AuthProvider';
import { SnackbarProvider } from 'notistack';
import { AuthenticatedApolloProvider } from './AuthenticatedApolloProvider';
import ErrorBoundary from '../routing/ErrorBoundary';
import CustomThemeProvider from './CustomThemeProvider';
import { CurrentUserProvider } from './CurrentUserProvider';
import { Initializer } from 'Initializer';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { nb } from 'date-fns/locale/nb';
import { UserPictureProvider } from './UserPictureProvider';

type ProvidersProps = {
  children?: React.ReactNode;
};

export const CustomThemeContext = createContext({
  currentTheme: 'light',
  setTheme: null,
});

/**
 * Wrapping component for the application. Place all application-scoped providers in here
 */
export const Providers = ({ children }: ProvidersProps) => {
  return (
    <Suspense fallback='loading'>
      <ErrorBoundary>
        <AuthProvider>
          <AuthenticatedApolloProvider>
            <CurrentUserProvider>
              <UserPictureProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
                  <CustomThemeProvider>
                    <SnackbarProvider
                      maxSnack={3}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <Initializer />

                      <DialogProvider>
                        <CssBaseline />

                        {children}
                      </DialogProvider>
                    </SnackbarProvider>
                  </CustomThemeProvider>
                </LocalizationProvider>
              </UserPictureProvider>
            </CurrentUserProvider>
          </AuthenticatedApolloProvider>
        </AuthProvider>
      </ErrorBoundary>
    </Suspense>
  );
};
