import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

type WindowSize = {
  height: number;
  width: number;
};

export default function useWindowSizePx(debounceMs = 200): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setWindowSize({ height: window.innerHeight, width: window.innerWidth });
    }

    const debounced = debounce(function () {
      handleResize();
    }, debounceMs);

    // Add event listener
    window.addEventListener('resize', debounced);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener('resize', debounced);
  }, [debounceMs]);

  return windowSize;
}
