export enum QueryParamKeys {
  SEARCH = 'search',
  ORGANIZATION_ID = 'organizationId',
  GEOFILTER_TYPE = 'geofilterType',
  GEOFILTER_AREA = 'geofilterArea',
  GEOFILTER_REGIONS = 'geofilterRegions',
  TEMPORALITY = 'temporalities',
  STATUS = 'status',
  CHARGER_TYPE = 'chargerType',
  BATTERY_TYPE = 'batteryType',
  USER_ID = 'userId',
  GENERIC_ASSET_TYPE = 'type',
}
