import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const useScreenSize = () => {
  const theme = useTheme();

  const smScreenOrUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdScreenOrUp = useMediaQuery(theme.breakpoints.up('md'));
  const lgScreenOrUp = useMediaQuery(theme.breakpoints.up('lg'));
  const xlScreen = useMediaQuery(theme.breakpoints.up('xl')); // Xl is the largest screen size

  return { smScreenOrUp, mdScreenOrUp, lgScreenOrUp, xlScreen };
};
