import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import { RoleAssignmentType, RoleBasicsFragment, useRolesQuery } from 'api/generated/graphql';
import { useTranslation } from 'react-i18next';

type SingleSelectProps = {
  type: 'single';
  role?: RoleBasicsFragment | null;
  onChange?: (role: RoleBasicsFragment | null) => void;
};

// Support for multiple project roles.
// Currently not in use.
type MultiSelectProps = {
  type: 'multiple';
  roles: RoleBasicsFragment[];
  onChange?: (added: RoleBasicsFragment[], removed: RoleBasicsFragment[]) => void;
};

type CustomSelectProps = SingleSelectProps | MultiSelectProps;

type CommonProps = {
  textFieldProps?: TextFieldProps;
  category: RoleAssignmentType;
};

type RolesSelectProps = CommonProps & CustomSelectProps;

export const RolesSelect = (props: RolesSelectProps) => {
  const { t } = useTranslation();

  const { data } = useRolesQuery({ fetchPolicy: 'cache-first' });
  let availableRoles = data?.roles.results ?? [];

  if (props.category) {
    availableRoles = availableRoles.filter((role) => role.assignableTo.includes(props.category)); // Todo: filter server-side
  }

  const multiple = props.type === 'multiple';

  return (
    <Autocomplete
      multiple={multiple}
      options={availableRoles}
      value={multiple ? props.roles : props.role}
      getOptionLabel={(opt) => {
        if (Array.isArray(opt)) {
          return opt[0]?.name || '';
        } else {
          return opt.name;
        }
      }}
      onChange={(e, values) => {
        if (multiple) {
          const addedRoles = (values as RoleBasicsFragment[]).filter(
            (value) => !props.roles.find((r) => r.id === value.id)
          );
          const removedRoles = props.roles.filter(
            (value) => !(values as RoleBasicsFragment[]).includes(value)
          );

          props.onChange?.(addedRoles, removedRoles);
        } else {
          props.onChange?.(values as RoleBasicsFragment | null);
        }
      }}
      renderInput={(params) => (
        <TextField
          margin='none'
          name='roles'
          label={t('ROLE_other')}
          {...props.textFieldProps}
          {...params}
        />
      )}
      sx={{ py: 0.5 }}
    />
  );
};
