// A wrapper hook around useParam to make sure a URL param is always present. Otherwise throw exception
// The benefit is that this will provide type safety and make sure the param is always present

import { useParams } from 'react-router';

export const useRequiredParam = (paramName: string) => {
  const params = useParams();

  const value = params[paramName];

  if (!value) throw Error(`Missing required URL param: ${paramName}`);

  return value;
};
