import { faQuestion } from '@fortawesome/pro-regular-svg-icons';
import { Box, Tooltip, useTheme } from '@mui/material';
import { FontAwesomeIconWrapper } from 'components/layout/FontAwesomeIconWrapper';
import { useTranslation } from 'react-i18next';

interface AvatarPlaceholderProps {
  width?: number;
  height?: number;
}
/**
 * Component used when there is no avatar to show.
 * @param interface
 * @returns a dashed circle with a question mark icon.
 */
export const AvatarPlaceholder = ({ width = 40, height = 40 }: AvatarPlaceholderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const color = theme.palette.text.disabled;

  return (
    <Tooltip title={t('PROJECT.TOOLTIP.UNKNOWN_OWNER')}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          width: width,
          height: height,
          borderRadius: '50%',
          borderColor: color,
          borderWidth: '1px',
          borderStyle: 'dashed',
          color: color,
        }}
      >
        <FontAwesomeIconWrapper icon={faQuestion} sx={{ width: width / 2, height: height / 2 }} />
      </Box>
    </Tooltip>
  );
};
