import { Dialog, DialogProps } from '@mui/material';
import { noop } from 'lodash';
import React, { useEffect } from 'react';
import { useScreenSize } from 'utils/hooks/useScreenSize';

type ProviderContext = readonly [(option: DialogOption) => void, () => void];

const DialogContext = React.createContext<ProviderContext>([noop, noop]);
export const useDialog = () => React.useContext(DialogContext);

type DialogParams = {
  children: React.ReactNode;
  closeOnBackdropClick?: boolean;
  open: boolean;
  onClose?: Function;
  onExited?: Function;
};
type DialogOption = Omit<DialogProps & DialogParams, 'open' | 'onExited'>;
type DialogContainerProps = DialogParams & {
  onClose: () => void;
  onKill: () => void;
};

function DialogContainer(props: DialogContainerProps) {
  const { children, onKill, closeOnBackdropClick, ...rest } = props;
  const { smScreenOrUp } = useScreenSize();

  return (
    <Dialog
      onAbort={onKill}
      fullScreen={!smScreenOrUp}
      scroll='paper'
      sx={{ m: !smScreenOrUp ? 1 : 0 }}
      {...rest}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && !closeOnBackdropClick) return;
        props.onClose();
      }}
    >
      {children}
    </Dialog>
  );
}

export const DialogProvider = ({ children }: { children?: React.ReactNode }) => {
  const [dialogs, setDialogs] = React.useState<DialogParams[]>([]);
  const createDialog = (option: DialogOption) => {
    const dialog = { ...option, open: true };
    setDialogs((dialogs) => [...dialogs, dialog]);
  };
  const closeDialog = () => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };
  const contextValue = React.useRef([createDialog, closeDialog] as const);

  useEffect(() => {
    window.addEventListener('popstate', closeDialog);
    return () => {
      window.removeEventListener('popstate', closeDialog);
    };
  }, []);

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {dialogs.map((dialog, i) => {
        const handleKill = () => {
          if (dialog.onExited) dialog.onExited();
          setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
        };

        return <DialogContainer {...dialog} key={i} onClose={closeDialog} onKill={handleKill} />;
      })}
    </DialogContext.Provider>
  );
};
