import { IconDefinition, IconName } from '@fortawesome/fontawesome-common-types';

export const faEnergyMeter: IconDefinition = {
  prefix: 'fab', // Font Awesome Custom
  iconName: 'energy-meter' as IconName,
  icon: [
    512,
    512,
    [],
    'e001',
    'M207 142C207 135 200 128 193 128H179C171.125 128 165 135 165 142V198C165 205.875 171.125 212 179 212H193C200 212 207 205.875 207 198V142ZM277 142C277 135 270 128 263 128H249C241.125 128 235 135 235 142V198C235 205.875 241.125 212 249 212H263C270 212 277 205.875 277 198V142ZM333 128C340 128 347 135 347 142V198C347 205.875 340 212 333 212H319C311.125 212 305 205.875 305 198V142C305 135 311.125 128 319 128H333ZM400 64H120C88.5 64 64 88.5 64 119.125V399.125C64 429.75 88.5 455.125 120 455.125H400C430.625 455.125 456 429.75 456 399.125V119.125C456 89.375 430.625 64 400 64ZM414 400C414 407.875 407 414 400 414H120C112.125 414 106 407.875 106 400V120C106 113 112.125 106 120 106H400C407 106 414 113 414 120V400ZM318.671 293.462H282.796L302.921 242.712C305.546 237.462 303.796 230.462 298.546 226.962C293.296 222.587 286.296 223.462 281.046 226.962L197.046 296.962C192.671 300.462 190.921 307.462 192.671 312.712C195.296 317.962 200.546 321.462 206.671 321.462H241.671L221.546 373.087C218.921 378.337 220.671 385.337 225.921 388.837C231.171 393.212 238.171 392.337 243.421 388.837L327.421 318.837C331.796 314.462 333.546 308.337 331.796 303.087C329.171 297.837 323.921 293.462 318.671 293.462Z',
  ],
};