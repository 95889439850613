import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import {
  OwnUserFragment,
  Permission,
  RoleAssignmentType,
  useWhoAmIQuery,
} from 'api/generated/graphql';
import { isDefined } from 'api/typeguards/isDefined';
import React, { useEffect } from 'react';
import { isAdmin } from 'rules/userRules';

export const CurrentUserContext = React.createContext<{
  currentUser: OwnUserFragment | null;
  logout: (options?: LogoutOptions | undefined) => void;
  updateCurrentUser: (user: OwnUserFragment) => void;
  hasPermission: (permission: Permission) => RoleAssignmentType | undefined;
  hasGlobalPermission: (permission: Permission) => boolean;
  isInternal: boolean;
  isDeveloper: boolean;
  isAdmin: boolean;
  latestTermsAndConditionsAccepted: boolean;
  globalPermissions: Permission[];
  organizationPermissions: Permission[];
  projectPermissions: Permission[];
}>({
  currentUser: null,
  logout: () => {},
  updateCurrentUser: () => {},
  hasPermission: () => undefined,
  hasGlobalPermission: () => false,
  isInternal: false,
  isDeveloper: false,
  isAdmin: false,
  latestTermsAndConditionsAccepted: false,
  globalPermissions: [],
  organizationPermissions: [],
  projectPermissions: [],
});

export const CurrentUserProvider = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useWhoAmIQuery();

  // currentUser is updated from the query, but could be overridden through the permission simulator. That is why we need to keep it in state
  const [currentUser, setCurrentUser] = React.useState<OwnUserFragment | null>(null);
  const isInternal = currentUser?.organization?.isInternal ?? false;
  const isDeveloper = currentUser?.email ? currentUser.email.indexOf('ilder') > -1 : false;
  const latestTermsAndConditionsAccepted = currentUser?.latestTermsAndConditionsAccepted ?? false;
  const { logout } = useAuth0();

  // Update currentUser when whoAmI query returns
  useEffect(() => {
    setCurrentUser(data?.whoAmI ?? null);
  }, [data?.whoAmI]);

  const globalPermissions =
    currentUser?.userRoles
      ?.map((role) => role.role?.permissions)
      .filter(isDefined)
      .flat() ?? [];
  const organizationPermissions =
    currentUser?.userOrganizationRoles
      ?.map((role) => role.role?.permissions)
      .filter(isDefined)
      .flat() ?? [];
  const projectPermissions =
    currentUser?.userProjectRoles
      ?.map((role) => role.role?.permissions)
      .filter(isDefined)
      .flat() ?? [];

  const hasPermission = (permission: Permission) => {
    // Check if the user has the permission in any of the global roles
    if (globalPermissions.includes(permission)) return RoleAssignmentType.UserRole;

    // Check if the user has the permission in any of the organizations
    if (organizationPermissions.includes(permission))
      return RoleAssignmentType.UserOrganizationRole;

    // Check if the user has the permission in any of the projects
    if (projectPermissions.includes(permission)) return RoleAssignmentType.UserProjectRole;

    return undefined; // No permission
  };

  const hasGlobalPermission = (permission: Permission) => globalPermissions.includes(permission);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        logout,
        updateCurrentUser: setCurrentUser,
        hasPermission,
        hasGlobalPermission,
        isAdmin: isAdmin(currentUser),
        isInternal,
        isDeveloper,
        latestTermsAndConditionsAccepted,
        globalPermissions,
        organizationPermissions,
        projectPermissions,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
