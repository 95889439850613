import { createTheme, darken, lighten, SvgIcon } from '@mui/material';
import { grey } from '@mui/material/colors';
import ConfettiExplosion from 'react-confetti-explosion';

const ilderPink = 'rgb(229, 52, 137)';
const ilderPinkDark = darken(ilderPink, 0.5);
const ilderPinkLight = lighten(ilderPink, 0.5);

const ilderBlue = 'rgb(26, 43, 74)';
const ilderBlueDark = darken(ilderPink, 0.5);
const ilderBlueLight = lighten(ilderPink, 0.5);

// Brand colors for Eviny
export const brandColors = {
  evinyGreen1: '#76e691',
  evinyGreen2: '#55b077',
  evinyGreen3: '#337a5c',
  evinyGreen4: '#124442',
  evinyCold1: '#e1c3ff',
  evinyCold2: '#554cfd',
  evinyCold3: '#2519ff',
  evinyCold4: '#2c3d5a',
  evinyWarm1: '#FCD300',
  evinyWarm2: '#fca50d',
  evinyWarm3: '#fc6d19',
  evinyWarm4: '#ed3f26',
  lightBackground: '#fafdfa',
  darkBackground: '#1b5b59',
  textPrimary: '#000000de',
  textSecondary: '#00000099',
  warningMain: '#ff9900',
  warningLight: '#ffb547',
  warningDark: '#c77700',
  infoMain: '#2196f3',
  infoLight: '#64b6f7',
  infoDark: '#0b79d0',
  successMain: '#4caf50',
  successLight: '#7bc67e',
  successDark: '#3b873e',
  errorMain: '#d22417',
  errorLight: '#f95e53',
  errorDark: '#c51407',
  assetReservationBackground: '#edf7fe',
};

export const pinkTheme = createTheme({
  id: 'pink',
  palette: {
    mode: 'light',
    primary: {
      main: ilderPink, //brandColors.evinyGreen3,
      dark: ilderPinkDark,
      light: ilderPinkLight,
      contrastText: '#FFF',
    },
    secondary: {
      main: ilderBlue,
      light: ilderBlueLight,
      dark: ilderBlueDark,
    },
    background: {
      default: brandColors.lightBackground,
    },
    warning: {
      main: brandColors.warningMain,
      light: brandColors.warningLight,
      dark: brandColors.warningDark,
      contrastText: '#FFF',
    },
    info: {
      main: brandColors.evinyCold1,
      light: brandColors.infoLight,
      dark: brandColors.infoDark,
    },
    success: {
      main: ilderPink,
      light: ilderPinkLight,
      dark: ilderPinkDark,
      contrastText: '#FFF',
    },
    error: {
      main: ilderPinkDark,
      light: brandColors.errorLight,
      dark: brandColors.errorDark,
    },
    text: {
      primary: brandColors.textPrimary,
      secondary: brandColors.textSecondary,
    },
  },
  sidebar: {
    background: ilderPinkDark,
    color: grey[200],
    header: {
      background: ilderPinkDark,
    },
    active: {
      background: ilderPink,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
      styleOverrides: {
        action: {
          marginTop: '-4px',
          marginRight: '-4px',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
    },
  },
  typography: {
    allVariants: {
      color: ilderPinkDark,
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    fontFamily: 'Montserrat',
    body1: {
      fontSize: '1.125rem',
      color: ilderPinkDark,
    },
    body2: {
      fontSize: '0.875rem',
      color: ilderPinkDark,
    },
    button: {
      fontSize: '1rem',
    },
    overline: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    caption: {
      fontWeight: 500,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1000,
      lg: 1300,
      xl: 2000,
    },
  },
});

export const EvinyLogoFullPink = () => (
  <>
    <SvgIcon viewBox='0 0 84 32' sx={{ width: 84, height: 32 }}>
      <svg
        width='84'
        height='32'
        viewBox='0 0 84 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_696_7572)'>
          <path
            d='M39.051 10.179C40.7688 10.179 42.1641 10.7922 43.2388 12.0217C44.3149 13.2495 44.7542 14.7368 44.5585 16.4831H35.7005C35.8536 17.3476 36.2503 18.0492 36.8939 18.5867C37.5356 19.1257 38.3114 19.3942 39.2185 19.3942C39.9169 19.3942 40.5414 19.2221 41.093 18.8806C41.6447 18.5392 42.0745 18.0745 42.3811 17.4885L44.2239 18.3041C43.7497 19.2126 43.0717 19.9381 42.1931 20.4834C41.3127 21.0269 40.3 21.3004 39.1556 21.3004C37.5501 21.3004 36.2037 20.7696 35.1145 19.7076C34.0239 18.6474 33.4805 17.3277 33.4805 15.7503C33.4805 14.1716 34.0144 12.8487 35.0828 11.7803C36.1493 10.7129 37.4722 10.179 39.051 10.179ZM39.051 12.0847C38.2403 12.0847 37.5388 12.3329 36.946 12.8274C36.3518 13.3238 35.9582 13.9682 35.7621 14.7648H42.338C42.1578 13.9682 41.7692 13.3238 41.1764 12.8274C40.5826 12.3329 39.8743 12.0847 39.051 12.0847Z'
            fill='white'
          />
          <path
            d='M50.276 20.4197L53.5649 10.3875H55.8057L52.0572 21.0896H48.3291L44.5806 10.3875H46.9668L50.276 20.4197Z'
            fill='white'
          />
          <path
            d='M56.9469 8.27286C56.6752 8.00747 56.5393 7.68637 56.5393 7.30867C56.5393 6.93232 56.6752 6.61032 56.9469 6.34448C57.2186 6.08045 57.5443 5.94775 57.9206 5.94775C58.2983 5.94775 58.6189 6.08045 58.8848 6.34448C59.1502 6.60987 59.2829 6.93232 59.2829 7.30867C59.2829 7.68637 59.1502 8.00701 58.8848 8.27286C58.6194 8.5387 58.2983 8.66958 57.9206 8.66958C57.5443 8.66958 57.2186 8.53825 56.9469 8.27286ZM59.0089 21.0895H56.8106V10.3874H59.0089V21.0895Z'
            fill='white'
          />
          <path
            d='M67.3016 10.179C68.5167 10.179 69.5076 10.6042 70.2757 11.4561C71.0438 12.308 71.4278 13.3903 71.4278 14.7024V21.0894H69.2295V15.0366C69.2295 14.2132 68.9641 13.5321 68.4329 12.995C67.9021 12.4579 67.2459 12.1889 66.4638 12.1889C65.5852 12.1889 64.866 12.4574 64.3067 12.995C63.7487 13.5321 63.4693 14.2132 63.4693 15.0366V21.0894H61.271V10.3873H63.4693V13.1512C63.7632 12.2301 64.2483 11.5045 64.9249 10.9733C65.6028 10.4443 66.3945 10.179 67.3016 10.179Z'
            fill='white'
          />
          <path
            d='M77.6275 20.4826L81.0206 10.3875H83.2615L78.2344 25.2773H75.9935L77.4192 21.0895H75.681L72.0366 10.3875H74.4229L77.6275 20.4826Z'
            fill='white'
          />
          <path
            d='M25.2737 21.775C26.1523 20.0006 26.5363 17.9808 26.3167 16.0116C25.6586 10.4398 21.0487 5.59439 15.5929 4.47305C14.3972 4.2294 13.1591 4.18456 11.9408 4.21219C7.83361 4.29597 3.59055 6.03912 2.31025 10.2627C-0.240386 18.6433 9.73843 32.4834 18.9537 28.455C21.8426 27.1698 23.9001 24.5548 25.2737 21.775ZM25.5622 21.9213C23.9716 24.933 21.9799 28.1131 18.8898 29.7752C15.9293 31.3879 12.4811 30.984 9.6157 29.3653C4.76352 26.6743 1.08248 20.6881 0.546724 15.1996C0.0951998 10.342 2.21062 6.28232 6.75212 4.31725C8.36484 3.61846 10.0912 3.20497 11.8235 2.93868C13.7659 2.64204 15.7477 2.48625 17.692 2.89928C22.094 3.74979 25.8325 7.30855 26.9099 11.6449C27.8162 15.0769 27.1817 18.8254 25.5622 21.9213Z'
            fill={ilderPinkLight}
          />
          <path
            d='M16.2152 26.6087C18.6649 26.2885 20.9601 25.0775 22.6158 23.2533C24.7195 20.9196 25.9336 17.8943 26.4173 14.8183C26.9001 11.5766 26.5455 8.00519 24.5333 5.3119C23.0859 3.38715 20.9628 2.19063 18.6255 1.6902C16.3986 1.24682 14.0717 1.64355 11.9984 2.52305C8.13806 4.17697 4.65765 7.39742 3.59972 11.5404C2.66542 15.0548 3.49646 18.8689 5.63633 21.795C8.06424 25.2025 11.9998 27.2119 16.2152 26.6087ZM16.256 26.9298C10.0053 27.7051 3.66402 24.3198 1.84434 18.0863C1.05632 15.4859 1.1546 12.63 2.10067 10.0848C4.50095 3.82916 11.5238 -0.407102 18.1735 0.651737C23.0565 1.51221 27.0455 4.96725 28.4227 9.75875C29.4539 13.3035 28.9023 17.2653 26.9839 20.4142C24.6878 24.1994 20.5783 26.4416 16.256 26.9298Z'
            fill={ilderPink}
          />
          <path
            d='M6.92746 25.8166C9.71223 28.2622 13.2307 29.7345 16.9665 29.5067C23.1624 29.1965 27.9013 23.895 27.5214 17.6865C27.4131 15.7835 26.8366 13.9035 25.9952 12.1971C23.3059 6.46448 16.4108 3.2558 10.4853 5.76749C9.03834 6.37798 7.69826 7.25793 6.58825 8.37067C3.75819 11.1908 2.54401 15.4932 3.26364 19.407C3.70973 21.8797 5.03169 24.1709 6.92746 25.8166ZM6.71234 26.0585C3.14046 22.8512 0.979757 17.797 2.02274 12.9978C2.81846 9.1691 5.59508 5.87619 9.16288 4.31102C13.2126 2.45194 18.2006 2.93607 21.8155 5.51796C23.7321 6.85351 25.2669 8.70037 26.3339 10.766C28.9575 15.6852 29.8605 22.2112 26.5594 27.0353C25.0867 29.1834 22.7792 30.7549 20.2277 31.2952C15.107 32.4355 10.3349 29.3623 6.71234 26.0585Z'
            fill={ilderPinkDark}
          />
        </g>
        <defs>
          <clipPath id='clip0_696_7572'>
            <rect width='83.2' height='32' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
    <ConfettiExplosion duration={4000} />
  </>
);
