import { AssetType } from 'api/generated/graphql';
import { RootRoutes } from 'enums/rootRoutes';

/*
 * Returns correct root route for the specific asset type.
 * Add support for new asset types in the future if necessary.
 */

export const getAssetTypeRoute = (assetType?: AssetType) => {
  if (assetType === AssetType.Battery) return RootRoutes.BATTERIES;
  if (assetType === AssetType.Charger) return RootRoutes.CHARGERS; // TODO: Change to use Assets
  if (assetType === AssetType.Switchboard) return RootRoutes.SWITCHBOARDS;
  if (assetType === AssetType.EnergyMeter) return RootRoutes.ENERGY_METERS;
  if (assetType === AssetType.Generic) return RootRoutes.ASSETS.replace(':type', 'generic');

  throw new Error('Invalid asset type type');
};
