import { StrictMode } from 'react';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';

import { getRouteConfig } from './routing/routes';
import 'regenerator-runtime/runtime'; // For some reason required by useAsyncDebounce by react-table: https://github.com/TanStack/table/issues/2071#issuecomment-679999096
import { useDocumentTitle } from 'utils/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';

const App = () => {
  const router = createBrowserRouter(getRouteConfig() as RouteObject[]); // Need to cast to RouteObject[] because we have extended the type with our own auth properties

  const { t } = useTranslation();
  useDocumentTitle(t('POWERSITE'));

  return (
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
};

export default App;
