import { IconDefinition, IconName } from '@fortawesome/fontawesome-common-types';

export const faSingleCharger: IconDefinition = {
  prefix: 'fab',
  iconName: 'single-charger' as IconName,
  icon: [
    512,
    512,
    [],
    'e001',
    'M 370.3296 186 H 406.2048 C 411.4528 186 416.704 190.375 419.328 195.625 C 421.0784 200.8749 419.328 207 414.9536 211.375 L 330.9536 281.375 C 325.7024 284.8749 318.7037 285.7501 313.4538 281.375 C 308.2035 277.8749 306.4538 270.8749 309.0787 265.625 L 329.2032 214 H 294.2035 C 288.0787 214 282.8285 210.4998 280.2035 205.2499 C 278.4538 200 280.2035 193 284.5786 189.5002 L 368.5792 119.5002 C 373.8272 116 380.8288 115.1251 386.08 119.5002 C 391.328 123 393.0784 130 390.4544 135.2499 L 370.3296 186 Z M 476 88 C 476 57.375 451.4976 32 420 32 H 279.9994 C 249.3741 32 223.9994 57.375 223.9994 88 V 352 C 223.9994 366.4 229.8234 375.0016 248.0019 375.0016 C 270.1075 375.0016 312.0019 375.0016 328.0032 375.0016 C 328.0032 398.4992 328.0032 360.4992 328.0032 384 C 328.0032 402.4992 316.289 408.4992 296.0019 408.4992 C 236.289 408.4992 187.9872 408.4992 179.4998 408.4992 C 166.3747 408.4992 154.9997 398 154.9997 384 L 154.9994 267.375 C 183.8742 258.625 203.9994 231.5002 203.9994 200 V 165 C 203.9994 153.625 195.2493 144 182.9994 144 H 175.9994 V 102 C 175.9994 95 169.8742 88 161.9994 88 C 154.9994 88 147.9994 95 147.9994 102 V 144 H 119.9994 V 102 C 119.9994 95 113.8742 88 105.9994 88 C 98.9994 88 91.9994 95 91.9994 102 V 144 H 84.9994 C 73.6243 144 63.9994 153.625 63.9994 165 V 200 C 63.9994 231.5002 84.9994 258.625 112.9994 267.375 L 112.9997 384 C 112.9997 420.7488 143.6246 450.4992 179.4998 450.4992 C 195.343 450.4992 264.0019 450.4992 312.0019 450.4992 C 344.0032 450.4992 370.0032 434.4992 370.0032 384 C 370.0032 360.4992 370.0032 398.4992 370.0032 375.0016 C 398.2592 375.0016 426.256 375.0016 455.0016 375.0016 C 467.2512 375.0016 476.0032 366.2496 476.0032 354 C 476.0032 230.3334 476 211.6666 476 88 Z M 434 88 L 434 332.9984 H 266.0013 L 265.9994 88 C 265.9994 81 272.9994 74 279.9994 74 H 420 C 427.8752 74 434 81 434 88 Z M 133.9994 228 C 119.1242 228 105.9994 215.7501 105.9994 200 V 186 H 161.9994 V 200 C 161.9994 215.7501 149.7491 228 133.9994 228 Z',
  ],
};
