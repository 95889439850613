import { AppBar, Toolbar, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { RootRoutes } from 'enums/rootRoutes';
import { brandColors } from 'theme/brandColors';
import { PowersiteLogoFull } from 'icons/PowersiteLogoFull';

export const LogoNavbar = () => {
  const navbarHeight = 64;

  return (
    <AppBar
      position='relative'
      data-testid='navbar'
      sx={{
        background: brandColors.darkBackground,
        color: '#fff',
        px: 0,
        height: navbarHeight,
      }}
      elevation={0}
    >
      <Toolbar sx={{ height: '100%' }}>
        <Link component={NavLink} to={RootRoutes.HOME}>
          <PowersiteLogoFull sx={{ display: 'flex' }} />
        </Link>
      </Toolbar>
    </AppBar>
  );
};
