import { CircularProgress, Box } from '@mui/material';

export const LoadingScreen = () => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      minHeight: '350px',
    }}
  >
    <CircularProgress aria-label='circular progress' size={70} color='primary' />
  </Box>
);
