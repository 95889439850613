import SvgIcon from '@mui/material/SvgIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { WithSx } from 'utils/mui';

export type FontAwesomeIconWrapperProps = FontAwesomeIconProps & WithSx;

export const FontAwesomeIconWrapper = ({ sx, ...props }: FontAwesomeIconWrapperProps) => (
  <SvgIcon sx={sx}>
    <FontAwesomeIcon {...props} />
  </SvgIcon>
);

export type FontAwesomePropsWithoutIcon = Omit<FontAwesomeIconWrapperProps, 'icon'>;
