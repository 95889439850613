import { useCurrentUser } from 'providers/CurrentUserProvider';
import { matchRoutes } from 'react-router-dom';
import { RootRoutes } from 'enums/rootRoutes';
import { Permission } from 'api/generated/graphql';
import { isProductionBranch } from 'utils/env';

type RouteAuthConfig = {
  permissions?: Permission[]; // Permissions required (at least one of them)to access the route
  internal?: boolean; // Only accessible to users in internal organizations
};

/**
 * A complete list of all routes and their required permissions
 * This is used to check if the current user has access to a given route
 * You can add routes with * to match all subroutes, or use exact paths
 * If a route is not listed here, it is not accessible to any user
 * Example of catch-all route: '/projects/*'
 * Example of sub-route: '/projects/:id'
 */
const routeAuthConfig: Record<string, RouteAuthConfig> = {
  ['/']: {}, // Home is always accessible
  [`${RootRoutes.OWN_PROFILE}/*`]: {}, // Own profile is always accessible
  [`${RootRoutes.POLICIES}/*`]: {}, // Policies are always accessible
  [`${RootRoutes.BATTERIES}/*`]: {
    permissions: [Permission.ReadAsset],
  },
  [`${RootRoutes.CHARGERS}/*`]: {
    permissions: [Permission.ReadAsset],
  },
  [`${RootRoutes.SWITCHBOARDS}/*`]: {
    permissions: [Permission.ReadAsset],
  },
  [`${RootRoutes.ENERGY_METERS}/*`]: {
    permissions: [Permission.ReadAsset],
  },
  [`${RootRoutes.ASSETS}/*`]: {
    permissions: [Permission.ReadAsset],
  },
  [`${RootRoutes.RESERVATIONS}/*`]: {
    permissions: [Permission.ReadReservation],
  },
  [`${RootRoutes.PROJECTS}/*`]: {
    permissions: [Permission.ReadProject],
  },
  [`${RootRoutes.MAP}/*`]: {
    permissions: [Permission.ReadAsset, Permission.ReadProject, Permission.ReadReservation],
  },
  [`${RootRoutes.MODELS_AND_VENDORS}/*`]: {
    internal: true,
    permissions: [Permission.WriteAssetModel],
  },
  [`${RootRoutes.USERS}/*`]: {
    internal: true,
    permissions: [Permission.WriteUser],
  },
  [`${RootRoutes.ORGANIZATIONS}/*`]: {
    permissions: [Permission.ReadOrganization],
    internal: true,
  },
  [`${RootRoutes.PROJECTS_TIMELINE}`]: {
    permissions: [Permission.ReadProject],
  },
  [`${RootRoutes.ASSETS_TIMELINE}`]: {
    permissions: [Permission.ReadAssetAllocation, Permission.ReadAssetReservation],
  },
  [`${RootRoutes.RESERVATIONS_TIMELINE}`]: {
    permissions: [Permission.ReadReservation],
  },
  [`${RootRoutes.TIMELINE}`]: {
    permissions: [
      Permission.ReadProject,
      Permission.ReadReservation,
      Permission.ReadAssetAllocation,
      Permission.ReadAssetReservation,
    ],
  },
  [`${RootRoutes.EVENTS}/*`]: {
    internal: true,
    permissions: [Permission.ReadEvent],
  },
  [`${RootRoutes.DECOMMISSIONED_ASSETS}/*`]: {
    permissions: [Permission.WriteAsset],
  },
  [`${RootRoutes.POLICY_ADMIN}/*`]: {
    permissions: [Permission.WritePolicyDocument],
  },
  [`${RootRoutes.POLICY_EDITOR}/*`]: {
    permissions: [Permission.WritePolicyDocument],
  },
  [`${RootRoutes.ROLE_ADMIN}/*`]: {
    internal: true,
    permissions: [Permission.WriteUser],
  },
  [`${RootRoutes.GRAPQHL_PLAYGROUND}/*`]: {
    internal: isProductionBranch ? true : false,
  },
};

// This hook is used to check if the current user has access to a given route
export const useRouteAccess = () => {
  const { hasPermission, currentUser } = useCurrentUser();

  return (url: string) => {
    const matchedRoutes = matchRoutes(
      Object.keys(routeAuthConfig).map((route: string) => ({ path: route })),
      url
    );

    // If no routes match, the user does not have access
    if (!matchedRoutes || !currentUser) return false;

    // Loop through all matched routes and verify that the user has access to all of them
    return matchedRoutes.every((r) => {
      const auth = routeAuthConfig[r.route.path as string];

      if (auth) {
        if (auth.internal && !currentUser?.organization?.isInternal) {
          return false; // Internal route
        }
        if (auth.permissions && auth.permissions.every((p) => !hasPermission(p))) {
          return false; // Permission based route
        }
      }

      return true;
    });
  };
};
