import {
  faAddressCard,
  faCalendar,
  faCalendars,
  faClipboardListCheck,
} from '@fortawesome/pro-regular-svg-icons';

export const projectIcon = faClipboardListCheck;
export const organizationIcon = faAddressCard;
export const multiReservationsIcon = faCalendars;
export const singleReservationIcon = faCalendar;
