import { Checkbox, CheckboxProps, FormControlLabel, Typography } from '@mui/material';
import { useField } from 'formik';
import { ChangeEvent } from 'react';

export type FormCheckboxProps<T extends object> = Omit<CheckboxProps, 'name'> & {
  name: keyof T;
  label: string;
};

export const FormCheckbox = <T extends object>({ name, ...props }: FormCheckboxProps<T>) => {
  const [field] = useField<boolean>(name as string);

  const configCheckbox = {
    ...field,
    ...props,
    onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      // First run the formik change handler
      field.onChange(e);
      // Then run any external change handler
      props.onChange && props.onChange(e, checked);
    },
    fullWidth: true,
    checked: field.value,
  };

  return (
    <FormControlLabel
      control={<Checkbox {...configCheckbox} />}
      label={<Typography variant='body2'>{props.label}</Typography>}
    />
  );
};
