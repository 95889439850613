import { ApolloError } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useSendFeedbackMutation } from 'api/generated/graphql';
import { FormCheckbox } from 'components/inputs/FormCheckbox';
import { FormTextField } from 'components/inputs/FormTextField';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type FeedbackDialogProps = {
  onClose: () => void;
};

type FeedbackInput = {
  title: string;
  message: string;
  contactUser: boolean;
};

export const FeedbackDialog = ({ onClose }: FeedbackDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  const [feedbackSent, setFeedbackSent] = useState(false);
  const [sendFeedback] = useSendFeedbackMutation({ onCompleted: onClose });

  const initialValues: FeedbackInput = {
    title: '',
    message: '',
    contactUser: false,
  };

  const handleSubmit = (input: FeedbackInput) => {
    const { title, message, contactUser } = input;

    const feedback = [{ key: 'Message', value: message }]; // Do not translate the key. The backend needs to be able to recognize it

    if (title) {
      feedback.push({ key: 'Title', value: title });
    }

    feedback.push({
      key: t('FEEDBACK.INCLUDE_URL.EMAIL_HEADER'),
      value: window.location.href,
    });

    feedback.push({
      key: t('FEEDBACK.INCLUDE_USER_INFO.EMAIL_HEADER'),
      value: currentUser?.email + ' ' + currentUser?.organization?.name,
    });

    feedback.push({
      key: t('FEEDBACK.EVINY_CONTACT.EMAIL_HEADER'),
      value: contactUser ? t('YES') : t('NO'),
    });

    sendFeedback({
      variables: {
        feedback,
      },
      onCompleted: () => {
        setFeedbackSent(true);
      },
      onError: (err: ApolloError) => {
        enqueueSnackbar(t('FEEDBACK.SEND_FAIL', { error: err.message }), { variant: 'error' });
      },
    });
  };

  return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <Form data-testid='vendor-editor-form'>
            <DialogTitle>{t('SEND_FEEDBACK')}</DialogTitle>

            <DialogContent sx={{ minWidth: { xs: 300, sm: 600 } }}>
              {!feedbackSent ? (
                <>
                  <FormTextField
                    name='title'
                    label={t('FEEDBACK.TITLE_LABEL')}
                    required={false}
                    sx={{ mb: 2 }}
                    helperText={t('FEEDBACK.TITLE.HELPER')}
                    inputProps={{ maxLength: 60 }}
                  />
                  <FormTextField
                    name='message'
                    label={t('YOUR_FEEDBACK')}
                    multiline
                    rows={4}
                    helperText={<Trans i18nKey='FEEDBACK.HELPER' />}
                  />
                  <FormCheckbox<FeedbackInput>
                    name='contactUser'
                    label={t('FEEDBACK.EVINY_CONTACT')}
                    sx={{ ml: 2 }}
                  />
                  <br />
                  <Typography variant='body2' sx={{ ml: 2 }}>
                    {t('FEEDBACK.INCLUDE_USER_INFO')}
                  </Typography>
                </>
              ) : (
                <Typography variant='body2'>{t('FEEDBACK.THANK_YOU')}</Typography>
              )}
            </DialogContent>

            <DialogActions>
              <Button color='primary' onClick={onClose}>
                {t('BUTTON_CLOSE')}
              </Button>
              {!feedbackSent && (
                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={!(formik.isValid && formik.dirty && formik.values.message.length > 0)}
                >
                  {t('BUTTON_SUBMIT')}
                </Button>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
  );
};
