import { AssetIdentifierFragment } from 'api/generated/graphql';
import { getAssetTypeRoute } from './getAssetTypeRoute';
import { getAssetTypeConfig } from './getAssetTypeConfig';

// Returns the URL for an asset details page

export const getAssetUrl = (asset: AssetIdentifierFragment) => {
  if (!asset.assetModel) return '';
  const type = getAssetTypeConfig(asset.assetModel).type;
  return `${getAssetTypeRoute(type)}/${asset.externalId}`;
};
