import { Box, Popover, PopoverOrigin } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Sx } from 'types/Sx';

type WithPopoverProps = {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  popoverContent: () => ReactNode;
  children?: ReactNode;
  popoverSx?: Sx;
  anchorEl?: HTMLElement | null; // Controlled state
  onAnchorElChanged?: (anchorEl: HTMLElement | null) => void; // Controlled state
};

export const WithPopover = ({
  anchorEl: controlledAnchorEl,
  onAnchorElChanged,
  popoverContent,
  children,
  popoverSx,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
}: WithPopoverProps) => {
  const [internalAnchorEl, setInternalAnchorEl] = useState<HTMLElement | null>(null);

  const anchorEl = controlledAnchorEl !== undefined ? controlledAnchorEl : internalAnchorEl;

  const open = Boolean(anchorEl);

  const setAnchorEl = (anchorEl: HTMLElement | null) => {
    if (onAnchorElChanged) {
      // Controlled state
      onAnchorElChanged(anchorEl);
    } else {
      // Uncontrolled state
      setInternalAnchorEl(anchorEl);
    }
  };

  return (
    <>
      <Box
        sx={{ cursor: 'pointer', display: 'inherit' }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {children}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        sx={popoverSx}
      >
        {open ? popoverContent() : null}
      </Popover>
    </>
  );
};
