import { PaletteOptions, createTheme, darken } from '@mui/material';
import { themeBase } from './themeBase';
import { isProductionBranch } from 'utils/env';
import { brandColors } from './brandColors';
import { grey } from '@mui/material/colors';

const darkPalette: PaletteOptions = {
  primary: {
    main: 'hsla(142, 37%, 51%, 1)',
    dark: 'hsla(155, 41%, 34%, 1)',
    light: 'hsla(143, 36%, 65%, 1)',
    contrastText: 'hsla(142, 37%, 12%, 1)',
  },
  secondary: {
    main: 'hsla(243, 97%, 72%, 1)',
    light: 'hsla(243, 98%, 80%, 1)',
    dark: 'hsla(243, 98%, 65%, 1)',
    contrastText: 'hsla(243, 97%, 7%, 1)',
  },
  background: {
    default: 'hsla(155, 41%, 8%, 1)',
    paper: 'hsla(156, 38%, 5%, 1)',
  },
  warning: {
    main: 'hsla(36, 79%, 50%, 1)',
    light: 'hsla(36, 79%, 65%, 1)',
    dark: 'hsla(36, 79%, 36%, 1)',
    contrastText: 'hsla(27, 98%, 12%, 1)',
  },
  info: {
    main: 'hsla(207, 68%, 54%, 1)',
    light: 'hsla(207, 67%, 70%, 1)',
    dark: 'hsla(206, 90%, 43%, 1)',
    contrastText: 'hsla(207, 68%, 12%, 1)',
  },
  success: {
    main: 'hsla(122, 39%, 49%, 1)',
    light: 'hsla(122, 40%, 63%, 1)',
    dark: 'hsla(122, 39%, 37%, 1)',
    contrastText: 'hsla(123, 41%, 10%, 1)',
  },
  error: {
    main: 'hsla(4, 93%, 65%, 1)',
    light: 'hsla(4, 94%, 75%, 1)',
    dark: 'hsla(4, 80%, 46%, 1)',
    contrastText: 'hsla(4, 93%, 13%, 1)',
  },
  text: {
    primary: 'hsla(0, 0%, 100%, 0.87)',
    secondary: 'hsla(0, 0%, 100%, 0.6)',
    disabled: 'hsla(0, 0%, 100%, 0.38)',
  },
  action: {
    active: 'hsla(0, 0%, 100%, 0.54)',
    hover: 'hsla(0, 0%, 100%, 0.04)',
    selected: 'hsla(0, 0%, 100%, 0.08)',
    disabled: 'hsla(0, 0%, 100%, 0.26)',
    disabledBackground: 'hsla(0, 0%, 100%, 0.12)',
    focus: 'hsla(0, 0%, 100%, 0.12)',
  },
  divider: 'hsla(0, 0%, 100%, 0.12)',
};

export const theme = createTheme({
  palette: { mode: 'dark', ...darkPalette },
  shadows: [
    'none', // 0dp
    '0px 2px 1px -1px rgba(255,255,255,0.2), 0px 1px 1px 0px rgba(255,255,255,0.14), 0px 1px 3px 0px rgba(255,255,255,0.12)', // 1dp
    '0px 3px 1px -2px rgba(255,255,255,0.2), 0px 2px 2px 0px rgba(255,255,255,0.14), 0px 1px 5px 0px rgba(255,255,255,0.12)', // 2dp
    '0px 3px 3px -2px rgba(255,255,255,0.2), 0px 3px 4px 0px rgba(255,255,255,0.14), 0px 1px 8px 0px rgba(255,255,255,0.12)', // 3dp
    '0px 2px 4px -1px rgba(255,255,255,0.2), 0px 4px 5px 0px rgba(255,255,255,0.14), 0px 1px 10px 0px rgba(255,255,255,0.12)', // 4dp
    '0px 3px 5px -1px rgba(255,255,255,0.2), 0px 5px 8px 0px rgba(255,255,255,0.14), 0px 1px 14px 0px rgba(255,255,255,0.12)', // 5dp
    '0px 3px 5px -1px rgba(255,255,255,0.2), 0px 6px 10px 0px rgba(255,255,255,0.14), 0px 1px 18px 0px rgba(255,255,255,0.12)', // 6dp
    '0px 4px 5px -2px rgba(255,255,255,0.2), 0px 7px 10px 1px rgba(255,255,255,0.14), 0px 2px 16px 1px rgba(255,255,255,0.12)', // 7dp
    '0px 5px 5px -3px rgba(255,255,255,0.2), 0px 8px 10px 1px rgba(255,255,255,0.14), 0px 3px 14px 2px rgba(255,255,255,0.12)', // 8dp
    '0px 5px 6px -3px rgba(255,255,255,0.2), 0px 9px 12px 1px rgba(255,255,255,0.14), 0px 3px 16px 2px rgba(255,255,255,0.12)', // 9dp
    '0px 6px 6px -3px rgba(255,255,255,0.2), 0px 10px 14px 1px rgba(255,255,255,0.14), 0px 4px 18px 3px rgba(255,255,255,0.12)', // 10dp
    '0px 6px 7px -4px rgba(255,255,255,0.2), 0px 11px 15px 1px rgba(255,255,255,0.14), 0px 4px 20px 3px rgba(255,255,255,0.12)', // 11dp
    '0px 7px 8px -4px rgba(255,255,255,0.2), 0px 12px 17px 2px rgba(255,255,255,0.14), 0px 5px 22px 4px rgba(255,255,255,0.12)', // 12dp
    '0px 7px 8px -4px rgba(255,255,255,0.2), 0px 13px 19px 2px rgba(255,255,255,0.14), 0px 5px 24px 4px rgba(255,255,255,0.12)', // 13dp
    '0px 7px 9px -4px rgba(255,255,255,0.2), 0px 14px 21px 2px rgba(255,255,255,0.14), 0px 5px 26px 4px rgba(255,255,255,0.12)', // 14dp
    '0px 8px 9px -5px rgba(255,255,255,0.2), 0px 15px 22px 2px rgba(255,255,255,0.14), 0px 6px 28px 5px rgba(255,255,255,0.12)', // 15dp
    '0px 8px 10px -5px rgba(255,255,255,0.2), 0px 16px 24px 2px rgba(255,255,255,0.14), 0px 6px 30px 5px rgba(255,255,255,0.12)', // 16dp
    '0px 8px 11px -5px rgba(255,255,255,0.2), 0px 17px 26px 2px rgba(255,255,255,0.14), 0px 6px 32px 5px rgba(255,255,255,0.12)', // 17dp
    '0px 9px 11px -5px rgba(255,255,255,0.2), 0px 18px 28px 2px rgba(255,255,255,0.14), 0px 7px 34px 6px rgba(255,255,255,0.12)', // 18dp
    '0px 9px 12px -6px rgba(255,255,255,0.2), 0px 19px 29px 2px rgba(255,255,255,0.14), 0px 7px 36px 6px rgba(255,255,255,0.12)', // 19dp
    '0px 10px 13px -6px rgba(255,255,255,0.2), 0px 20px 31px 3px rgba(255,255,255,0.14), 0px 8px 38px 7px rgba(255,255,255,0.12)', // 20dp
    '0px 10px 13px -6px rgba(255,255,255,0.2), 0px 21px 33px 3px rgba(255,255,255,0.14), 0px 8px 40px 7px rgba(255,255,255,0.12)', // 21dp
    '0px 10px 14px -6px rgba(255,255,255,0.2), 0px 22px 35px 3px rgba(255,255,255,0.14), 0px 8px 42px 7px rgba(255,255,255,0.12)', // 22dp
    '0px 11px 14px -7px rgba(255,255,255,0.2), 0px 23px 36px 3px rgba(255,255,255,0.14), 0px 9px 44px 8px rgba(255,255,255,0.12)', // 23dp
    '0px 11px 15px -7px rgba(255,255,255,0.2), 0px 24px 38px 3px rgba(255,255,255,0.14), 0px 9px 46px 8px rgba(255,255,255,0.12)', // 24dp
  ],
  ...themeBase,
  sidebar: {
    background: isProductionBranch
      ? darken(brandColors.evinyGreen4, 0.4)
      : darken(brandColors.evinyCold4, 0.4),
    color: grey[200],
    header: {
      background: isProductionBranch
        ? darken(brandColors.darkBackground, 0.4)
        : darken(brandColors.evinyCold4, 0.4),
    },
    active: {
      background: isProductionBranch
        ? darken(brandColors.evinyGreen3, 0.4)
        : darken(brandColors.evinyCold4, 0.1),
    },
  },
});
