import { SvgIcon } from '@mui/material';

export const EvinyLogoIcon = () => (
  <SvgIcon viewBox='0 0 32 32' sx={{ width: 26, height: 32 }}>
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.2739 21.775C26.1525 20.0006 26.5366 17.9808 26.3169 16.0116C25.6589 10.4398 21.049 5.59439 15.5931 4.47305C14.3975 4.2294 13.1593 4.18456 11.941 4.21219C7.83385 4.29597 3.59079 6.03912 2.31049 10.2627C-0.240142 18.6433 9.73868 32.4834 18.9539 28.455C21.8429 27.1698 23.9003 24.5548 25.2739 21.775ZM25.5624 21.9213C23.9719 24.933 21.9801 28.1131 18.8901 29.7752C15.9296 31.3879 12.4813 30.984 9.61595 29.3653C4.76376 26.6743 1.08273 20.6881 0.546968 15.1996C0.0954439 10.342 2.21086 6.28232 6.75237 4.31725C8.36508 3.61846 10.0915 3.20497 11.8238 2.93868C13.7662 2.64204 15.748 2.48625 17.6922 2.89928C22.0942 3.74979 25.8328 7.30855 26.9102 11.6449C27.8164 15.0769 27.1819 18.8254 25.5624 21.9213Z'
        fill='#76E691'
      />
      <path
        d='M16.2152 26.6087C18.6649 26.2885 20.9601 25.0775 22.6158 23.2533C24.7195 20.9196 25.9336 17.8943 26.4173 14.8183C26.9001 11.5766 26.5455 8.00519 24.5333 5.3119C23.0859 3.38715 20.9628 2.19063 18.6255 1.6902C16.3986 1.24682 14.0717 1.64355 11.9984 2.52305C8.13806 4.17697 4.65765 7.39742 3.59972 11.5404C2.66542 15.0548 3.49646 18.8689 5.63633 21.795C8.06424 25.2025 11.9998 27.2119 16.2152 26.6087ZM16.256 26.9298C10.0053 27.7051 3.66402 24.3198 1.84434 18.0863C1.05632 15.4859 1.1546 12.63 2.10067 10.0848C4.50095 3.82916 11.5238 -0.407102 18.1735 0.651737C23.0565 1.51221 27.0455 4.96725 28.4227 9.75875C29.4539 13.3035 28.9023 17.2653 26.9839 20.4142C24.6878 24.1994 20.5783 26.4416 16.256 26.9298Z'
        fill='#55B077'
      />
      <path
        d='M6.92746 25.8166C9.71223 28.2622 13.2307 29.7345 16.9665 29.5067C23.1624 29.1965 27.9013 23.895 27.5214 17.6865C27.4131 15.7835 26.8366 13.9035 25.9952 12.1971C23.3059 6.46448 16.4108 3.2558 10.4853 5.76749C9.03834 6.37798 7.69826 7.25793 6.58825 8.37067C3.75819 11.1908 2.54401 15.4932 3.26364 19.407C3.70973 21.8797 5.03169 24.1709 6.92746 25.8166ZM6.71234 26.0585C3.14046 22.8512 0.979757 17.797 2.02274 12.9978C2.81846 9.1691 5.59508 5.87619 9.16288 4.31102C13.2126 2.45194 18.2006 2.93607 21.8155 5.51796C23.7321 6.85351 25.2669 8.70037 26.3339 10.766C28.9575 15.6852 29.8605 22.2112 26.5594 27.0353C25.0867 29.1834 22.7792 30.7549 20.2277 31.2952C15.107 32.4355 10.3349 29.3623 6.71234 26.0585Z'
        fill='#337A5C'
      />
      <path
        d='M14.5705 10.179C16.2883 10.179 17.6837 10.7922 18.7584 12.0217C19.8344 13.2495 20.2737 14.7368 20.0781 16.4831H11.2201C11.3731 17.3476 11.7699 18.0492 12.4134 18.5867C13.0552 19.1257 13.8309 19.3942 14.7381 19.3942C15.4364 19.3942 16.061 19.2221 16.6126 18.8806C17.1642 18.5392 17.594 18.0745 17.9006 17.4885L19.7434 18.3041C19.2692 19.2126 18.5912 19.9381 17.7126 20.4834C16.8322 21.0269 15.8196 21.3004 14.6751 21.3004C13.0696 21.3004 11.7232 20.7696 10.634 19.7076C9.54347 18.6474 9 17.3277 9 15.7503C9 14.1716 9.53395 12.8487 10.6023 11.7803C11.6689 10.7129 12.9917 10.179 14.5705 10.179ZM14.5705 12.0847C13.7598 12.0847 13.0583 12.3329 12.4655 12.8274C11.8713 13.3238 11.4777 13.9682 11.2816 14.7648H17.8576C17.6773 13.9682 17.2887 13.3238 16.6959 12.8274C16.1022 12.3329 15.3939 12.0847 14.5705 12.0847Z'
        fill='white'
      />
    </svg>
  </SvgIcon>
);
