import { ReactElement } from 'react';
import { ListItemIcon, ListItemText, MenuItem, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from 'utils/hooks/useSidebar';

export type MobileMenuItemProps = {
  href: string;
  icon: ReactElement | undefined;
  title: string;
  disabled?: boolean;
  closeMenu: () => void;
};

export const MobileMenuItem = ({ icon, href, title, disabled, closeMenu }: MobileMenuItemProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { isActive } = useSidebar(href);

  return (
    <MenuItem
      key={title}
      onClick={() => {
        navigate(href);
        closeMenu();
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transition: '0.2s ease-in-out',
        borderRadius: 1,
        mx: 0.5,
        px: 1,
        background: (theme) => (isActive ? theme.sidebar?.active.background : undefined),
        '&:hover': {
          background: theme.sidebar?.active.background,
        },
      }}
      disabled={disabled}
    >
      <ListItemIcon
        sx={{
          color: isActive ? 'white' : 'text.primary',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText>
        <Typography
          sx={{
            fontSize: 12,
            color: isActive ? 'white' : 'text.primary',
            textAlign: 'center',
            width: 60,
            pt: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>
      </ListItemText>
    </MenuItem>
  );
};
