import { Box } from '@mui/material';

type DefaultPageContainerProps = {
  children?: React.ReactNode;
};

// A wrapper component that should be used as a wrapper for pages that should be scrollable like details page etc.
export const DefaultPageContainer = ({ children }: DefaultPageContainerProps) => {
  return (
    <Box
      sx={{
        overflow: {
          sm: 'auto',
        },
        height: '100%',
      }}
    >
      <Box
        py={3}
        px={{ xs: 1, sm: 3 }}
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        maxHeight='100%'
      >
        {children}
      </Box>
    </Box>
  );
};
