import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faSwitchboard: IconDefinition = {
  prefix: 'fab', // Font Awesome Custom
  iconName: 'cabinet-filing',
  icon: [
    512,
    512,
    [],
    'e001',
    'M 456 120 C 456 89.375 430.625 64 400 64 L 120 64 C 88.5 64 64 89.375 64 120 L 64 400 C 64 431.5 88.5 456 120 456 L 400 456 C 430.625 456 456 431.5 456 400 Z M 120 106 L 400 106 C 407 106 414 113 414 120 L 414 400 C 414 407.875 407 414 400 414 L 120 414 C 112.125 414 106 407.875 106 400 L 106 285.078125 L 147.65625 285.078125 L 272.414062 338.855469 C 275.75 366.320312 298.882812 388 328 388 C 358.625 388 384 362.625 384 332 C 384 300.5 358.625 276 328 276 C 310.664062 276 295.453125 283.417969 285.273438 295.339844 L 201.738281 267.273438 C 198.558594 266.203125 198.53125 261.71875 201.699219 260.613281 L 287.398438 230.671875 C 297.511719 241.316406 311.835938 248 328 248 C 358.625 248 384 222.625 384 192 C 384 160.5 358.625 136 328 136 C 296.617188 136 272.179688 160.320312 272 191.652344 L 147.65625 243.078125 L 106 243.078125 L 106 120 C 106 113 112.125 106 120 106 Z M 349 332 C 349 343.375 339.375 353 328 353 C 315.75 353 307 343.375 307 332 C 307 319.75 315.75 311 328 311 C 339.375 311 349 319.75 349 332 Z M 307 192 C 307 179.75 315.75 171 328 171 C 339.375 171 349 179.75 349 192 C 349 203.375 339.375 213 328 213 C 315.75 213 307 203.375 307 192 Z M 307 192 ',
  ],
};
