import { useBasePath } from '../routerUtils';

export const useSidebar = (href: string) => {
  const basePath = useBasePath();
  const firstElementFromHref = href?.split('/')[1];
  const firstElementFromBasePath = basePath?.split('/')[1];

  const isActive = firstElementFromHref === firstElementFromBasePath;

  return { isActive };
};
