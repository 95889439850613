import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { localStorageKeys } from 'utils/constants';

export const useSidebarCollapse = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const isLgScreen = window.innerWidth >= theme.breakpoints.values.lg;

    // initialize with local storage if value exists
    const savedOpen = localStorage.getItem(localStorageKeys.sidebarOpen);
    if (savedOpen) setSidebarOpen(savedOpen === 'true');
    else setSidebarOpen(isLgScreen);

    // handle resize
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024) setSidebarOpen(false);
    });
  }, [theme, setSidebarOpen]);

  useEffect(() => {
    // save preference on every change
    localStorage.setItem(localStorageKeys.sidebarOpen, sidebarOpen.toString());
  }, [sidebarOpen]);

  return { sidebarOpen, setSidebarOpen };
};
