import { IconDefinition, IconName } from '@fortawesome/fontawesome-common-types';

export const faGenericAsset: IconDefinition = {
  prefix: 'fab',
  iconName: 'genericAsset' as IconName,
  icon: [
    16,
    16,
    [],
    'f3b6',
    'M9.7 8C9.7 8.71719 10.2578 9.275 10.975 9.275C11.6656 9.275 12.25 8.71719 12.25 8C12.25 7.30937 11.6656 6.725 10.975 6.725C10.2578 6.725 9.7 7.30937 9.7 8ZM6.66429 5.025C6.66429 5.74219 7.2221 6.3 7.93929 6.3C8.62991 6.3 9.21429 5.74219 9.21429 5.025C9.21429 4.33438 8.62991 3.75 7.93929 3.75C7.2221 3.75 6.66429 4.33438 6.66429 5.025ZM6.3 8C6.3 8.71719 5.71563 9.275 5.025 9.275C4.30781 9.275 3.75 8.71719 3.75 8C3.75 7.30937 4.30781 6.725 5.025 6.725C5.71563 6.725 6.3 7.30937 6.3 8Z M6.66429 10.975C6.66429 10.2844 7.2221 9.7 7.93929 9.7C8.62991 9.7 9.21429 10.2844 9.21429 10.975C9.21429 11.6922 8.62991 12.25 7.93929 12.25C7.2221 12.25 6.66429 11.6922 6.66429 10.975Z M8 15C4.11719 15 1 11.8828 1 8C1 4.14453 4.11719 1 8 1C11.8555 1 15 4.14453 15 8C15 11.8828 11.8555 15 8 15ZM8 2.3125C4.85547 2.3125 2.3125 4.88281 2.3125 8C2.3125 11.1445 4.85547 13.6875 8 13.6875C11.1172 13.6875 13.6875 11.1445 13.6875 8C13.6875 4.88281 11.1172 2.3125 8 2.3125Z',
  ],
};
