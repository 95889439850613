import { ThemeOptions, darken, lighten } from '@mui/material';
import { grey } from '@mui/material/colors';
import { isProductionBranch } from 'utils/env';
import { brandColors } from './brandColors';

export const themeBase: ThemeOptions = {
  sidebar: {
    background: isProductionBranch ? brandColors.evinyGreen4 : brandColors.evinyCold4,
    color: grey[200],
    header: {
      background: isProductionBranch
        ? brandColors.darkBackground
        : darken(brandColors.evinyCold4, 0.2),
    },
    active: {
      background: isProductionBranch
        ? brandColors.evinyGreen3
        : lighten(brandColors.evinyCold4, 0.1),
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset', // Removes weird linear-gradient background on dark theme cards
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '& > form': {
            display: 'contents', // Allows scroll: 'paper' to work with formik forms
          },
          backgroundImage: 'unset', // Removes weird linear-gradient background on dark theme dialogs
        },
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
      styleOverrides: {
        shrink: {
          fontSize: 16,
          textTransform: 'capitalize',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset', // Removes weird linear-gradient background on dark theme paper
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
      styleOverrides: {
        action: {
          marginTop: '-4px',
          marginRight: '-4px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'warning' && {
              color: theme.palette.warning.dark, // Darken the text color on outlined warning chips to increase contrast
            }),
        }),
      },
      defaultProps: {
        size: 'small',
      },
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    fontFamily: 'Montserrat',
    body1: {
      fontSize: '1.125rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    button: {
      fontSize: '1rem',
    },
    overline: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    caption: {
      fontWeight: 500,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1000,
      lg: 1300,
      xl: 2000,
    },
  },
};
