import { Tooltip, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LinkButton } from 'components/buttons/LinkButton';
import { ReactElement } from 'react';
import { useSidebar } from 'utils/hooks/useSidebar';

export type SidebarLinkProps = {
  href: string;
  icon: ReactElement | undefined;
  title: string;
  hasTooltip?: boolean;
  disabled?: boolean;
};

export const SidebarLink = ({ icon, href, title, hasTooltip, disabled }: SidebarLinkProps) => {
  const { isActive } = useSidebar(href);

  return (
    <Tooltip role='tooltip' title={hasTooltip ? title : ''} placement='right' arrow>
      <Box width='100%'>
        <LinkButton
          href={href}
          disabled={disabled}
          linkProps={{ sx: { width: '100%' } }}
          sx={{
            px: 0.5,
            py: 1,
            borderBottom: '1px solid #0f3836',
            pl: 1,
            width: '100%',
            background: (theme) => (isActive ? theme.sidebar?.active.background : undefined),
            transition: '0.3s all ease-in-out',
            '&:hover': {
              background: (theme) => theme.sidebar?.active.background,
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: 'white',
              '& svg': { height: 24, width: 24, ml: 1 },
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText sx={{ m: 0 }}>
            <Typography
              sx={{
                fontSize: 14,
                pl: 1.5,
                color: 'white',
                textTransform: 'capitalize',
                textAlign: 'left',
              }}
            >
              {title}
            </Typography>
          </ListItemText>
        </LinkButton>
      </Box>
    </Tooltip>
  );
};
